import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 1.5rem 1rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 1.75rem 2rem;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 1.75rem 4rem;
  }
`

export const Card = styled.div<{ $cardColor: string }>`
  background-color: ${({ $cardColor = 'var(--porto-banking-15)' }) => $cardColor};
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: fit-content;

  align-items: center;

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 16px 16px 0;

  @media screen and (max-width: ${breakpoints.sm}) {
    border-radius: 0 0 16px 16px;
  }
`

export const WrapperContent = styled.div`
  padding: 60px 40px;

  @media (max-width: calc(${breakpoints.sm})) {
    padding: 24px;
  }
`
