import {
  BoxCards,
  InfoAndActionSection,
  Faq,
  AlsoOffer,
  Header,
  Anchors,
} from '@/modules/static-page/components'

export const useCapitalizacaoContent = () => {
  const capitalizacaoContent = [
    {
      Component: Header,
    "props": { 
      "title": "Título de Capitalização",
      "subtitle": "Garantia de aluguel sem comprovação de renda e resgate de até 100% do valor!",
      "description": "Alugue sem fiador ou comprovação de renda e ainda resgate o valor da garantia no final do contrato.",
      "shortcuts": [
        {
          label: 'Nova Proposta',
          color: 'seguros',
          icon: 'add',
          url: '/contratos/novo/capitalizacao',
          hide: null,
          product: 'capitalizacao',
        },
      ],
      side: [
        {
          Component: Anchors,
          props: {
            title: 'Navegue pelos tópicos',
            values: [
              {
                label: 'Conheça os benefícios para você',
                anchor: '#benefits_you',
              },
              {
                label: 'Benefícios para seus clientes',
                anchor: '#benefits_clients',
              },
              {
                label: 'Sobre os sorteios de prêmios',
                anchor: '#prize_draw',
              },
              {
                label: 'Condições gerais',
                anchor: '#conditions',
              },
              {
                label: 'Tire suas dúvidas',
                anchor: '#faq',
              },
            ],
          },
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'secondary',
      title: 'Conheça os benefícios para você',
      anchor_id: 'benefits_you',
      values: [
        {
          cards: [
            {
              icon: 'handshake',
              title: 'Garantia de recebimento',
              subtitle: 'Receba as taxas administrativas.',
            },
            {
              icon: 'circlecheck',
              title: 'Agilidade',
              subtitle: 'Mais velocidade no processo de locação.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'primary',
      title: 'Benefícios para seus clientes',
      anchor_id: 'benefits_clients',
      values: [
        {
          title: 'Inquilino',
          cards: [
            {
              icon: 'check',
              title: 'Contratação fácil',
              subtitle: 'Sem análise de crédito ou comprovação de renda.',
            },
            {
              icon: 'handcoins2',
              title: 'Resgate',
              subtitle:
                'Até 100% do valor da garantia no final do contrato para o inquilino.',
            },
            {
              "icon": "creditcard",
              "title": "Pagamento à vista no boleto ou em até 18x no cartão de crédito",
              "subtitle": "Acréscimo de juros da administradora do cartão.",
            },
            {
              "icon": "cards",
              "title": "Pague com até 2 cartões diferentes", 
              "subtitle": "Um deles deve estar no nome do titular.",
            },
            {
              icon: 'tools',
              title: 'Serviços de assistência para o imóvel',
              subtitle: 'Chaveiro, reparo elétrico e hidráulico.',
              link: {
                label: 'Saiba mais',
                url: 'https://www.portoseguro.com.br/NovoInstitucional/static_files/CGs/portocap_aluguel/portocap_aluguel_e_portocap_caucao_atual.pdf',
              },
            },
            {
              "icon": "calendar",
              "title": "Sorteios mensais",
              "subtitle": "Até 5x o valor da garantia para o contratante do título.",
            }
          ],
        },
        {
          title: 'Proprietário',
          cards: [
            {
              icon: 'handshake',
              title: 'Não perder a locação',
              subtitle: 'Oportunidade do inquilino alugar sem fiador.',
            },
            {
              icon: 'circledollarsign',
              title: 'Garantia de recebimento',
              subtitle: 'No caso do inquilino atrasar o pagamento.',
            },
            {
              icon: 'money',
              title: 'Valor do título',
              subtitle:
                'Acordado entre as partes interessadas no ato da contratação',
            },
            {
              "icon": "shield",
              "title": "Tranquilidade",
              "subtitle": "O imóvel protegido em todos os momentos.",
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'tertiary',
      title: 'Sobre o sorteio de prêmios',
      anchor_id: 'prize_draw',
      values: [
        {
          columns: 3,
          cards: [
            {
              icon: 'circledollarsign',
              iconColor: 'porto-banking-primary',
              title: 'Prêmios em dinheiro',
              subtitle:
                'Os inquilinos concorrem a prêmios de 1 a 5x o valor do título contratado.',
            },
            {
              icon: 'calendar',
              iconColor: 'porto-banking-primary',
              title: 'Sorteios mensais',
              subtitle:
                'O sorteio é realizado no último sábado de todo mês. Os inquilinos concorrem a todos os sorteios, durante a vigência do título.',
            },
            {
              icon: 'wallet',
              iconColor: 'porto-banking-primary',
              title: 'Número da sorte',
              subtitle:
                'Cada título tem um número da sorte e o sorteio é realizado pela Loteria Federal.',
            },
          ],
        },
      ],
      actions: [
        {
          label: 'Consulte os sorteios',
          url: 'https://www.portoseguro.com.br/titulo-de-capitalizacao-para-aluguel#textobotao-numeros-da-sorte-portocap',
        },
      ],
    },
  },
  {
    Component: InfoAndActionSection,
    props: {
      variant: 'primary',
      title: 'Condições gerais',
      anchor_id: 'conditions',
      values: {
        info: 'Acesse os documentos que apresentam as condições de contratação.',
        action: {
          type: 'link',
          label: 'Abrir condições gerais',
          icon: 'externalLink',
          url: 'https://www.portoseguro.com.br/titulo-de-capitalizacao-para-aluguel#condicoes-gerais',
        },
      },
    },
  },
  {
    Component: Faq,
    props: {
      variant: 'primary',
      title: 'Tire suas dúvidas',
      anchor_id: 'faq',
      values: [
        {
          question: 'Como funciona o Título de Capitalização?',
          answer:
            'O Título de Capitalização é uma aplicação financeira com pagamento único e utilizado como garantia para locação de um imóvel. <br /> <br /> Após escolher o imóvel, o inquilino deve negociar o preço da garantia com o proprietário e a imobiliária. Com a ajuda de um corretor, poderá adquirir um título de capitalização no mesmo valor, utilizado como garantia para locação de um imóvel. Este título de capitalização poderá ter validade de 12, 15, 18, 24 ou 30 meses. <br /> <br /> Durante o período, o inquilino participa de sorteios mensais pela Loteria Federal e concorre a prêmios de até 5x o valor da garantia conforme a regra do momento da contratação. <br /> <br /> Além disso, conta com serviços emergenciais à residência alugada e, em caso de permanência até o final sem utilizar a garantia, resgate percentual do valor capitalizado conforme o plano escolhido. <br /> <br /> Serviços exclusivos para imóvel residencial. <br /> <br /> O valor capitalizado será atualizado mensalmente (Taxa Referencial + 0,35% ao mês) garantindo o percentual de resgate informado no final do período em validade.',
        },
        {
          question:
            'O que o inquilino deve fazer se o Título de Capitalização for sorteado?',
          answer:
            'A Porto Bank fará contato com o inquilino e após confirmar o recebimento da documentação, pagará a premiação que tem retenção de 30% de IR. Além disso, o inquilino participa dos demais sorteios até o final do período em validade.',
        },
        {
          question: 'Posso transferir o Título de Capitalização?',
          answer:
            'Sim, o inquilino pode transferir o título, desde que haja a liberação da caução pela imobiliária ou pelo proprietário do imóvel. A transferência deve ser solicitada à Porto Bank, sem qualquer encargo.',
        },
        {
          question:
            'Quais serviços para residência o inquilino tem direito com o Título de Capitalização?',
          answer:
            "Disponibilizamos os seguintes serviços para imóveis residenciais alugados: chaveiro comum, conversão de gás, instalação de fechadura ou trava tetra, reparo hidráulico, reparo elétrico e troca de segredos de fechadura. Para mais informações <a href='https://www.portoseguro.com.br/NovoInstitucional/static_files/CGs/portocap_aluguel/portocap_aluguel_e_portocap_caucao_atual.pdf' target='_blank'>consulte aqui.</a>",
        },
        {
          question:
            'Quando posso solicitar o resgate do Título de Capitalização?',
          answer:
            'O resgate da garantia ocorre conforme a liberação da caução (acordo entre inquilino e proprietário do imóvel). Caso o resgate aconteça antes do final da validade do título, a restituição segue conforme a tabela de resgate aplicada para o produto. Por favor, consulte as condições gerais do título.',
        },
      ],
    },
  },
  {
    Component: AlsoOffer,
    props: {
      variant: 'primary',
      title: 'Ofereça também',
      values: [
        {
          icon: 'fireflame',
          upperTitle: 'Garantia de aluguel',
          title: 'Seguro Incêndio',
          description:
            'Cobertura contra incêndio para imóveis alugados. Obrigatório por lei.',
          href: '/produtos/seguro-incendio',
        },
        {
          icon: 'ticket',
          upperTitle: 'Garantia de aluguel',
          title: 'Fiança Locatícia',
          description:
            'Substituiu o fiador, tem a vigência do contrato de locação e serviços assistenciais para o imóvel.',
          href: '/produtos/fianca-locaticia',
        },
      ],
    },
    },
  ]

  return capitalizacaoContent
}

