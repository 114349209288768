import * as S from './styles'

export const HomeBanner: React.FC<{
  children: React.ReactNode
  imageUrl: string
  color?: string
}> = ({ imageUrl, children, color }) => {
  return (
    <S.Container>
      <S.Card $cardColor={color}>
        <S.WrapperContent>{children} </S.WrapperContent>

        <S.Image src={imageUrl} />
      </S.Card>
    </S.Container>
  )
}
