import api from '../../index'

export async function updateRole(
  organizationId: string,
  memberEmail: string,
  role: 'owner' | 'admin' | 'member'
) {
  const response = await api.instance.v2.patch(
    `/organizations/${organizationId}/members/${memberEmail}/role`,
    { role }
  )

  return response.data
}

export async function remove(id: string, email: string) {
  try {
    const response = await api.instance.v2.delete(
      `/organizations/${id}/members`,
      {
        data: {
          email
        }
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function invite(
  id: string,
  payload: {
    email: string
  }
) {
  try {
    const response = await api.instance.v2.post(
      `/organizations/${id}/members`,
      {
        email: payload.email,
        role: 'member',
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAcceptanceTerms(): Promise<{accepted: boolean, date?: Date}> {
  try {
    const response = await api.instance.v2.get(
      `/organizations/member/acceptance_terms`
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function registerAcceptanceTerms() {
  try {
    const response = await api.instance.v2.put(
      `/organizations/member/acceptance_terms`
    )

    return response.data
  } catch (error) {
    throw error
  }
}
