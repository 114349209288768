import { useState } from 'react'
import { Container, Detail, Middle, Right, Options } from './styles'
import { Conditional, Popover } from '@/components'
import { IListProps } from './interfaces'
import { DetailsProduct } from '@/screens/Renovations/Components/DetailsProduct'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
const List = (props: IListProps) => {
  const [pressed, setPressed] = useState(false)
  function onPress() {
    setPressed(!true)
  }
  return (
    <>
      <Container
        {...(props.status !== 'error' ? { failure: false } : { failure: true })}
        role="button"
        aria-pressed={pressed}
        aria-haspopup={true}
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            onPress()
          }
        }}
        onClick={() => setPressed(props.status !== 'error' && !pressed)}
      >
        <Middle>
          {props.details.map((detail, index) => (
            <Detail.Item key={index}>
              <Detail.Info>
                <Detail.Label>{detail.label}</Detail.Label>
                <Detail.Value>{detail.value}</Detail.Value>
              </Detail.Info>
            </Detail.Item>
          ))}
        </Middle>
        <Right>
          <Conditional when={props.popover.length > 0}>
            <Popover title="Gerar PDF" items={props.popover}>
              <Options.Toggle>
                <Options.Icon />
                <Options.Label>Mais opções</Options.Label>
              </Options.Toggle>
            </Popover>
          </Conditional>
        </Right>

        {props.errorLabel && (
          <Typography
            as="span"
            variant="porto-text-label-bold"
            sx={{
              position: 'absolute',
              top: 'calc(100% - 12px)',
              textWrap: 'nowrap',
            }}
          >
            Retorno do produto:{' '}
            <Typography as="span" color="porto-primitive-red-100" variant="porto-text-label-bold">
              {Array.isArray(props.errorLabel) ? props.errorLabel.join(', ') : props.errorLabel}
            </Typography>
          </Typography>
        )}
      </Container>

      <Conditional when={pressed}>
        <DetailsProduct statusContract={props.status} details={props.contract} pressed={pressed} />
      </Conditional>
    </>
  )
}
export default List
