import * as yup from 'yup'

import { birthdateValidator } from '@/tools/validators'

import { useCurrentOrganization } from '@/modules'
import { Contract } from '@/modules/contract/interface'
import { BRLMoneyToNumber, formatMoneyBRL } from '@/tools'
import { isValidMobilePhone } from '@brazilian-utils/brazilian-utils'
import { formatToCPFOrCNPJ, formatToPhone, isCEP, isCPF } from 'brazilian-values'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Products } from '../../shared/entities'
import initialValuesEmpty from './initialValues'

export type FormSchemaType = typeof initialValuesEmpty

const address = yup.object().shape({
  zipcode: yup
    .string()
    .test((value, context) => {
      if (!isCEP(value))
        return context.createError({
          message: 'Por favor insira um CEP válido.',
        })

      return true
    })
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string().max(20),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})

export const customer = yup.object({
  document: yup
    .string()
    .required('Campo CPF é obrigatório.')
    .test('document-test', 'Por favor insira um CPF válido.', value => {
      return value && isCPF(value)
    }),
  email: yup.string().email('E-mail inválido').required('Campo e-mail é obrigatório.'),
  name: yup.string().required('Campo nome é obrigatório.'),
  birthdate: yup
    .string()
    .test('invalid-date', 'Favor informar data de nascimento válida.', birthdateValidator)
    .required('Campo é obrigatório.'),
  phone: yup
    .string()
    .test(
      'is-valid-phone',
      'Por favor insira um número válido',
      value => !!value && isValidMobilePhone(value),
    )
    .required('Campo telefone/celular é obrigatório.'),
})

export const validationSchema = yup.object({
  partner: yup.string().required('Este campo é obrigatório'),
  susep: yup.string().required('Campo SUSEP é obrigatório'),
  address,
  customers: yup
    .array()
    .of(customer)
    .test({
      name: 'test-duplicate-documents',
      test: (value, context) => {
        const errorIndex = value.findIndex((findValue, findIndex, customersArray) => {
          const duplicateIndex = customersArray.findIndex(
            value => value.document === findValue.document,
          )

          return findIndex !== duplicateIndex
        })

        if (errorIndex > -1) {
          return context.createError({
            path: `customers.${errorIndex}.document`,
            message: 'Este CPF já está em uso por um dos inquilinos',
          })
        }

        return true
      },
    }),
  rent_due_date: yup
    .number()
    .min(1, 'Data mínima para vencimento é 1')
    .max(28, 'Data máxima para vencimento é 28')
    .required('Campo dia de vencimento é obrigatório')
    .transform(value => (isNaN(value) ? undefined : value)),

  coverages: yup.object({
    coverages_main: yup.object().shape({
      rent: yup
        .string()
        .test({
          message: 'Cobertura mínima de R$ 200,00.',
          test: value => BRLMoneyToNumber(value) >= 200,
        })
        .test({
          message: 'Cobertura máxima de R$ 12.000,00.',
          test: value => BRLMoneyToNumber(value) <= 12000,
        })
        .required('Campo valor do aluguel é obrigatório.'),
      iptu: yup.string().test({
        message: 'O Valor não pode ser maior que o valor do Aluguel.',
        test: (value, context) => {
          return BRLMoneyToNumber(value) <= BRLMoneyToNumber(context.parent?.rent)
        },
      }),
      condominium: yup.string().test({
        message: 'Cobertura máxima de R$ 12.000,00.',
        test: value => BRLMoneyToNumber(value) <= 12000,
      }),
      water: yup.string().test({
        message: 'O Valor não pode ser maior que o valor do Aluguel.',
        test: (value, context) => {
          return BRLMoneyToNumber(value) <= BRLMoneyToNumber(context.parent?.rent)
        },
      }),
      gas: yup.string().test({
        message: 'O Valor não pode ser maior que o valor do Aluguel.',
        test: (value, context) => {
          return BRLMoneyToNumber(value) <= BRLMoneyToNumber(context.parent?.rent)
        },
      }),
      electricity: yup.string().test({
        message: 'O Valor não pode ser maior que o valor do Aluguel.',
        test: (value, context) => {
          return BRLMoneyToNumber(value) <= BRLMoneyToNumber(context.parent?.rent)
        },
      }),
    }),
  }),

  contract_period_start: yup.string().required('Campo obrigatório.'),
  contract_period_end: yup.string().required('Campo obrigatório.'),
  validity_period_start: yup.string().required('Campo obrigatório.'),
  validity_period_end: yup.string().required('Campo obrigatório.'),
  estate_readjustment: yup
    .string()
    .notOneOf([''], 'Selecione o índice de contrato.')
    .required('Selecione o índice de contrato.'),
})

function parseDate(date) {
  if (!date) return undefined

  const formatedDate = dayjs(date).utc().format('YYYY-MM-DD')

  return dayjs(formatedDate).utc().toDate()
}

export function useInitialStateForm(): {
  initialValues: FormSchemaType
  resend: boolean
  status?: ReturnType<typeof useQuery>['status']
} {
  const organization = useCurrentOrganization()
  const isBroker = organization?.type === 'BROKER'
  const { state: contract } = useLocation<Contract<Products.ESSENCIAL>>()

  const data = useMemo(() => {
    if (!contract?.id) return

    const customers = contract.payload.customers.map(e => ({
      ...e,
      hasSocialName: !!e.socialName,
      document: formatToCPFOrCNPJ(e.document),
      phone: formatToPhone(e.phone),
    }))

    const coverages_main = { rent: '' }
    Object.entries(contract?.payload.coverages?.coverages_main).forEach(([key, value]) => {
      coverages_main[key] = !!value ? formatMoneyBRL(value) : undefined
    })

    const coverages = {
      ...contract.payload.coverages,
      coverages_main,
      rent: coverages_main.rent,
    }

    return {
      ...initialValuesEmpty,
      ...contract.payload,
      customers,
      coverages,
      estate_readjustment: contract.payload?.readjustment,
      contract_period_start: parseDate(contract.payload?.period?.start),
      contract_period_end: parseDate(contract.payload?.period?.end),
      partner: typeof contract.partner == 'string' ? contract.partner : contract.partner.id,
      broker: typeof contract.broker == 'string' ? contract.broker : contract.broker.id,
      susep: typeof contract.broker == 'string' ? contract.broker : contract.broker.id,
    }
  }, [contract])

  const defaultInitial = useMemo(
    () => ({
      ...initialValuesEmpty,
      partner: isBroker ? '' : organization.id,
    }),
    [isBroker, organization.id],
  )

  const resend = !!contract?.id
  // @ts-ignore
  const initialValues: FormSchemaType = resend ? data : defaultInitial

  return {
    initialValues,
    resend,
  }
}
