import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { useCurrentUser } from '@/modules'
import { useRegistration } from '@/tools'
import { useHistory } from 'react-router'

const PartnerRegistration = ({ wizard, errorStep }) => {
  const currentUser = useCurrentUser()
  const history = useHistory()

  useRegistration(currentUser.store?.email, {
    onSuccess() {
      history.go(0)
    },
    onError(error) {
      // @ts-ignore
      switch (error?.data?.code) {
        case '@partners/already-exists': {
          history.go(0)
          break
        }
        case '@partners/not-found': {
          wizard.next()
          break
        }
        default: {
          wizard.push(errorStep)
        }
      }
    },
  })

  return (
    <Flex
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '3rem',
          }}
        >
          <Loader size="3rem" color="porto-banking-primary" />
        </Box>
        <Typography
          variant="porto-title-5-semibold"
          content={`
            Aguarde, estamos registrando seus dados no sistema.
          `}
        />
      </Flex>
    </Flex>
  )
}

export default PartnerRegistration
