import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { useMemo } from 'react'

export const paymentsParse = {
  CC: 'creditCard',
  FAT: 'invoiceWithoutDown',
  CP: 'portoSeguroCard',
}

/**
 * ? Não se assuste, isso pega os valores de um enum ou objeto,
 * ? e tranforma em um type que só aceita aqueles valores
 **/
type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : never
}[keyof T]

type P = Lowercase<`${StringValues<typeof Products>}`>

export function usePaymentEnable(product: P, selectedBudget?: string, settings?: any) {
  const opennedContract = useContract()

  const budgetPayments = useMemo(
    () =>
      opennedContract?.budgets?.find(budget => {
        return budget.id === selectedBudget
      })?.payment_methods,
    [opennedContract?.budgets, selectedBudget],
  )

  const methodEnabled = useMemo(() => {
    if (settings)
      return budgetPayments?.filter(method => {
        return settings[`${product}.payments.${paymentsParse[method.id]}.enabled`]
      })

    return budgetPayments
  }, [budgetPayments, product, settings])

  return methodEnabled || []
}
