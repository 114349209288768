import Button from '@/components/Actions/Button'
import { useContract, useCurrentUser, useOrganizations } from '@/modules'
import { api } from '@/services'
import { pxToRem } from '@/styles/sheets/helpers'
import { dashText } from '@/tools'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionContainer,
  Container,
  DataContainer,
  DataWrapper,
  Divider,
  Header,
  MessageContainer,
  TitleContainer,
} from './styles'

const NewError = ({
  product,
  errorTitle,
  errorMessage,
  showProductReturnLabel,
}: {
  product: string
  errorTitle?: string
  errorMessage?: string | string[]
  showProductReturnLabel?: boolean
}) => {
  const contract = useContract()
  const currentUser = useCurrentUser()
  const org = useOrganizations()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const partnerName = org.store.current.find(org => org.type === 'REAL_ESTATE')?.name

  const budget = contract.budgets?.find(budget => budget.id === contract.payload?.payment?.budget)

  const data = {
    'ID do contrato:': contract?.id ?? '',
    'Orçamento ou Proposta:': budget?.id ?? contract?.policy?.id ?? '',
    'Data e hora:': format(new Date(), 'Pp', {
      locale: ptBR,
    }),
    'Produto:': product,
    'Susep:': contract.broker?.id ?? '',
    'Nome:': partnerName ?? '',
    'E-mail:': currentUser.store.email ?? '',
  }

  const handleCopy = () => {
    const text = Object.entries(data).reduce((acc, [label, data]) => `${acc}\n${label} ${data}`, '')

    navigator.clipboard.writeText(text.trim())
  }

  const handleResendClick = async () => {
    try {
      setIsLoading(true)
      await api.contracts.calculate(contract.id)
      history.go(0)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Header>
        <Typography color="system-primitive-neutral-900" variant="porto-title-4-bold">
          {product}
        </Typography>
      </Header>
      <Container>
        <TitleContainer>
          <Icon size={pxToRem('25px')} color="#FF5A40" name="alert" />
          <Typography color="system-primitive-neutral-800" variant="porto-title-5-semibold">
            {errorTitle || 'Algo deu errado.'}
          </Typography>
        </TitleContainer>

        {!!errorMessage && (
          <MessageContainer>
            {showProductReturnLabel && (
              <Typography
                as="strong"
                color="system-primitive-neutral-900"
                variant="porto-text-body-1-bold"
              >
                Retorno do produto:
              </Typography>
            )}

            {Array.isArray(errorMessage) ? (
              errorMessage.map(message => (
                <Typography
                  key={dashText(message)}
                  color="system-primitive-neutral-900"
                  variant="porto-text-body-1-regular"
                >
                  {message}
                </Typography>
              ))
            ) : (
              <Typography color="system-primitive-neutral-900" variant="porto-text-body-1-regular">
                {errorMessage}
              </Typography>
            )}
          </MessageContainer>
        )}

        <ActionContainer>
          <Button
            isDisabled={contract.metadata.error.code === '@aluguel/missing-documents'}
            isLoading={isLoading}
            onClick={handleResendClick}
            data-test-id="resend"
          >
            Reenviar
          </Button>
          <Button data-test-id="back-start" kind="ghost" onClick={() => history.push('/contratos')}>
            Voltar ao início
          </Button>
        </ActionContainer>
      </Container>
      <DataContainer>
        <Typography color="system-primitive-neutral-900" variant="porto-text-body-1-regular">
          Você pode solicitar a tratativa deste erro com seu consultor ou direto pelo chat. Basta
          seguir as orientações e enviar as informações abaixo quando solicitadas.
        </Typography>
        <Divider />
        <Button
          icon="duplicate"
          isFeatherIcons={false}
          iconLeft={true}
          type="button"
          kind="simple"
          onClick={handleCopy}
          data-test-id="copy-information"
        >
          Copiar informações para a área de transferência
        </Button>
        <DataWrapper>
          {Object.entries(data).map(([label, data], index) => (
            <Typography
              key={`${index}-${label}`}
              color="system-primitive-neutral-900"
              variant="porto-text-caption-regular"
              data-test-id={`information-${label}-${data}`}
            >{`${label} ${data}`}</Typography>
          ))}
        </DataWrapper>
      </DataContainer>
    </>
  )
}

export default NewError
