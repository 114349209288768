import { useState } from 'react'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { WizardContext } from 'react-albus'
import { Link, Logo } from '../../components'
import { ActionTypes, useNewAccount } from './../context'
import { Button } from './styles'

type ProfileType = 'BROKER' | 'REAL_ESTATE' | 'MEMBER'

const ProfileTypeSelector: React.FC<{
  wizard: WizardContext
  partnerAccreditatedStep: string
  createMemberStep: string
  brokerAccreditatedStep: string
}> = ({ wizard, partnerAccreditatedStep, createMemberStep, brokerAccreditatedStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const newAccount = useNewAccount()

  const handleProfileTypeClick = async (profileType: ProfileType) => {
    setIsLoading(true)

    newAccount.dispatch({
      type: ActionTypes.UPDATE,
      payload: {
        profileType,
      },
    })

    switch (profileType) {
      case 'BROKER': {
        wizard.push(brokerAccreditatedStep)
        return
      }
      case 'MEMBER': {
        wizard.push(createMemberStep)
        return
      }
      case 'REAL_ESTATE': {
        wizard.push(partnerAccreditatedStep)
        return
      }
      default: {
        throw new Error('Etapa de cadastro não mapeada')
      }
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Logo />
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography variant="porto-title-5-bold" content="Que tipo de conta você quer criar?" />
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                marginTop: '1rem',
              }}
            >
              <Button
                type="submit"
                size="block"
                isLoading={isLoading}
                isDisabled={isLoading}
                kind="ghost"
                onClick={() => handleProfileTypeClick('REAL_ESTATE')}
              >
                <Typography as="span" variant="porto-text-body-1-regular">
                  Conta de imobiliária (organização)
                </Typography>
                <Icon name="shortarrowright" />
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: '1rem',
              }}
            >
              <Button
                type="submit"
                size="block"
                isLoading={isLoading}
                isDisabled={isLoading}
                kind="ghost"
                onClick={() => handleProfileTypeClick('BROKER')}
              >
                <Typography as="span" variant="porto-text-body-1-regular">
                  Conta de corretora (organização)
                </Typography>
                <Icon name="shortarrowright" />
              </Button>
            </Box>
            <Box
              sx={{
                marginTop: '1rem',
              }}
            >
              <Button
                type="submit"
                size="block"
                isLoading={isLoading}
                isDisabled={isLoading}
                kind="ghost"
                onClick={() => handleProfileTypeClick('MEMBER')}
              >
                <Typography as="span" variant="porto-text-body-1-regular">
                  Membro de imobiliária ou corretora
                </Typography>
                <Icon name="shortarrowright" />
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProfileTypeSelector
