import React, { useMemo, useState } from 'react'

import { Actions, Icons, Popover } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { IPopoverItems } from '@/components/Popover/interfaces'
import { Contract } from '@/modules/contract/interface'
import { plansParse } from '@/screens/Contracts/Products/Aluguel/Budgets/utils'
import { budgetsParse } from '@/screens/Contracts/Products/Essencial/Budgets/utils'
import { PortoImobiliaria } from '@/screens/Contracts/Products/Imobiliaria/constants'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatMoneyBRL, usePagination } from '@/tools'
import { ITableColumn } from '../Table'
import * as S from './styles'

export const TableItem: React.FC<{
  columns: ITableColumn[]
  contract: Contract<any>
  options: IPopoverItems[]
  color: 'system-surface-primary' | 'system-surface-secondary'
}> = ({ columns, contract, options, color }) => {
  const { updating } = usePagination()
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(!isOpen)

  const budget = useMemo(() => {
    const index = contract?.budgets?.length

    if (!index || contract.product === Products.ESSENCIAL) return

    return contract.budgets[index - 1].id
  }, [contract.budgets, contract.product])

  const planType = useMemo(() => {
    switch (contract.product) {
      case Products.ESSENCIAL:
        return budgetsParse[contract.payment?.budget]

      case Products.IMOBILIARIA:
        return PortoImobiliaria.Plan[contract.payload?.contract?.plan]

      case Products.ALUGUEL:
        return plansParse[contract.payload?.contract?.plan?.toLowerCase()]

      default:
        return
    }
  }, [contract.payload?.contract?.plan, contract.payment?.budget, contract.product])

  const pac = useMemo(() => {
    if (contract.product !== Products.ALUGUEL) return

    return contract.payload?.pac
  }, [contract.payload?.pac, contract.product])

  const policy = useMemo(() => {
    return Number(contract.policy?.id) ? contract.policy?.id : false
  }, [contract.policy?.id])

  const totalValue = useMemo(() => {
    switch (contract.product) {
      case Products.CAPITALIZACAO:
        if (!contract.payload?.contract?.total_value) return
        return {
          value: contract.payload?.contract?.total_value,
          message: 'Total do título',
        }

      case Products.ESSENCIAL:
        if (!contract.payload?.coverages?.rent) return

        return {
          value: contract.payload.coverages.rent,
          message: 'Valor do aluguel',
        }

      default:
        const index = contract.budgets?.length

        if (!index) return

        if (!contract.budgets[index - 1].quotation.prize.total) return

        return {
          value: contract.budgets[index - 1].quotation.prize.total,
          message: 'Valor do seguro',
        }
    }
  }, [
    contract.budgets,
    contract.payload?.contract?.total_value,
    contract.payload?.coverages?.rent,
    contract.product,
  ])

  return (
    <>
      {columns.map(column => (
        <S.Item key={column.label} onClick={toggleOpen} bgColor={color} data-test-id={column.label}>
          {column.render(contract)}
        </S.Item>
      ))}
      <S.ActionContainer bgColor={color}>
        {updating.includes(contract.id) ? (
          <S.LoaderContainer>
            <Icons.Loader />
          </S.LoaderContainer>
        ) : (
          <Popover items={options}>
            <Actions.Button icon="more-vertical" kind="simple" />
          </Popover>
        )}
      </S.ActionContainer>
      <S.Details bgColor={color} isOpen={isOpen}>
        <S.DetailsContainer>
          {budget && (
            <S.DetailsItem>
              <Typography variant="porto-text-caption-regular" content="Nº do orçamento" />
              <Typography
                variant="porto-text-body-2-regular-bold"
                content={budget}
                data-test-id="budget"
              />
            </S.DetailsItem>
          )}

          {pac && (
            <S.DetailsItem>
              <Typography variant="porto-text-caption-regular" content="Nº do PAC" />
              <Typography
                variant="porto-text-body-2-regular-bold"
                content={pac}
                data-test-id="pac"
              />
            </S.DetailsItem>
          )}

          {policy && (
            <S.DetailsItem>
              <Typography variant="porto-text-caption-regular" content="Nº da apólice" />
              <Typography
                variant="porto-text-body-2-regular-bold"
                content={policy}
                data-test-id="apolice"
              />
            </S.DetailsItem>
          )}

          {totalValue && (
            <S.DetailsItem>
              <Typography variant="porto-text-caption-regular" content={totalValue.message} />
              <Typography
                variant="porto-text-body-2-regular-bold"
                content={formatMoneyBRL(totalValue.value)}
                data-test-id="total"
              />
            </S.DetailsItem>
          )}

          {planType && (
            <S.DetailsItem>
              <Typography variant="porto-text-caption-regular" content="Tipo de plano" />
              <Typography
                variant="porto-text-body-2-regular-bold"
                content={planType}
                data-test-id="tipo-plano"
              />
            </S.DetailsItem>
          )}
        </S.DetailsContainer>
        <S.DetailsActions>
          <Actions.Link to={`/contratos/${contract.id}`} data-test-id="view-details">
            Ver detalhes
            <Icons.FeatherIcons name="arrow-right" />
          </Actions.Link>
        </S.DetailsActions>
      </S.Details>
    </>
  )
}
