export const commission = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '10': '10',
  '11': '11',
  '12': '12',
  '13': '13',
  '14': '14',
  '15': '15',
  '16': '16',
  '17': '17',
  '18': '18',
  '19': '19',
  '20': '20',
  '21': '154',
  '22': '155',
  '23': '156',
  '24': '157',
  '25': '158',
  '26': '159',
  '27': '160',
  '28': '161',
  '29': '162',
  '30': '163',
  '31': '164',
  '32': '165',
  '33': '166',
  '34': '167',
  '35': '168',
  '36': '169',
  '37': '170',
  '38': '171',
  '39': '172',
  '40': '173',
  '41': '174',
  '42': '175',
  '43': '176',
  '44': '177',
  '45': '178',
  '46': '179',
  '47': '180',
  '48': '181',
  '49': '182',
  '50': '183',
  '51': '184',
  '52': '185',
  '53': '186',
  '54': '187',
  '55': '188',
  '56': '189',
  '57': '190',
  '58': '191',
  '59': '192',
  '60': '193',
  '61': '194',
  '62': '195',
  '63': '196',
  '64': '197',
  '65': '198',
  '66': '199',
  '67': '200',
  '68': '201',
  '69': '202',
  '70': '203',
} as const

/**
 * Aplica-se aos produtos: `Incêndio`
 *
 * Recebe um valor de porcentagem e retorna o código de operação referente para aquele valor de comissão.
 *
 * @param percent Porcemtagem de comissão que será aplicada
 * @returns Código de operação referente à porcentagem de comissão aplicada
 */
export function getOperationCodeFromPercent(percent: string | number): string | undefined {
  return commission[`${percent}`]
}

/**
 * Aplica-se aos produtos: `Incêndio`
 *
 * Rece um código de operação e retorna a porcentagem de comissão aplicada referente ao código informado
 *
 * @param code Código de operação
 * @returns Porcentagem de comissão aplicada referente ao código de operação aplicado
 */
export function getPercentFromOperationCode(code: string | number): string | undefined {
  const selected = Object.entries(commission).find(([_, value]) => value === `${code}`)

  if (!selected) return

  return selected[0]
}
