import { rem } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Actions } from '@/components'
import { FeatherIcons } from '@/components/Icons'
import { themes } from '@/styles'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const Container = styled.div`
  overflow: auto;
`

export const ContainerWrapper = styled.div`
  background: #ffff;
  border-radius: 8px;
`

export const FailureLabel = styled.div`
  display: flex;
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
  margin-bottom: 20px;
  margin-left: 16px;

  color: #1f1f1f;

  span {
    color: ${themes.palletes.primary};
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
  }

  span:hover {
    text-decoration: underline;
  }
`

export const ErrorStyle = styled.div`
  color: #842310;
  margin-left: 4px;
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 16px 24px 16px;
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const Action = styled(Actions.Button)<{ kind?: any }>``

export const Icon = styled(FeatherIcons)`
  margin-right: 8px;
  height: ${rem('20px')};

  ${media.greaterThan('huge')`
    height: ${rem('26px')};
  `}

  ${media.lessThan('medium')`
    height: ${rem('26px')};
  `}
`

export const FormWrapper = styled.div`
  height: 600px;

  padding: 0 24px;

  height: 425px;
  width: 100%;
  overflow-y: scroll;

  ${media.greaterThan('huge')`
    height: 525px;
  `}
`

export const FormFirstCheck = styled.div<{ checkGlobal?: boolean }>`
  display: flex;
  padding: 0 0 12px;
  margin: 0 0 9px;
  border-bottom: 1px solid rgba(179, 179, 179, 0.5);

  &:hover {
    .form-check-input {
      border: 1px solid ${themes.palletes.primary};
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  &:last-child {
    border-bottom: hidden;
    margin: 0;
  }

  .form-check-input {
    cursor: pointer;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 0.5px solid #5f5f5f;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    background: white;

    &:hover {
      border: 1px solid ${themes.palletes.primary};
    }
  }

  .form-check-input {
    border-radius: 4px;
  }

  .form-check-input:before {
    content: '';
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 4px;
    opacity: 0;
    transition: all 350ms ease-in-out;
    position: absolute;
  }

  .form-check-input:before {
    border-radius: 0;
    background: transparent;
    border: 2px solid white;
    border-left: 0;
    border-top: 0;
    width: 3px;
    height: 7px;
    transform: rotate(45deg);
    top: 1px;
  }
  .form-check-input:checked {
    background: ${themes.palletes.primary};

    transition: all 350ms ease-in-out;
  }

  .form-check-input:checked:before {
    opacity: 1;
  }

  ${props =>
    props.checkGlobal &&
    css`
      border: 0;
      margin-left: 16px;
    `}
`

export const FormCheck = styled.div<{
  checkGlobal?: boolean
  failure?: boolean
}>`
  display: flex;
  padding: 16px 0 16px;
  width: 100%;
  /* margin: 0 0 9px; */
  border-bottom: 1px solid rgba(179, 179, 179, 0.5);

  &:hover {
    .form-check-input {
      border: 1px solid ${themes.palletes.primary};
    }

    background-color: ${props => (props.failure ? '' : '#f7f7f7')};
    padding: ${props => (props.failure ? '' : '12px')};
    ${props =>
      !props.failure &&
      css`
        cursor: pointer;
      `};
  }

  &:last-child {
    border-bottom: hidden;
    margin: 0;
  }

  .form-check-input {
    cursor: pointer;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 0.5px solid #5f5f5f;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    background: white;
    font-size: 14px;

    &:hover {
      border: 1px solid ${themes.palletes.primary};
    }
  }

  .form-check-input {
    border-radius: 4px;
  }

  .form-check-input:before {
    content: '';
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 4px;
    opacity: 0;
    transition: all 350ms ease-in-out;
    position: absolute;
  }

  .form-check-input:before {
    border-radius: 0;
    background: transparent;
    border: 2px solid white;
    border-left: 0;
    border-top: 0;
    width: 3px;
    height: 7px;
    transform: rotate(45deg);
    top: 1px;
  }
  .form-check-input:checked {
    background: ${themes.palletes.primary};

    transition: all 350ms ease-in-out;
  }

  .form-check-input:checked:before {
    opacity: 1;
  }

  ${props =>
    props.checkGlobal &&
    css`
      border: 0;
      margin-left: 16px;
    `}
`

export const FirstChild = styled.div`
  width: 225px;
  display: flex;
  flex-direction: column;
  align-items: start;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;

  color: #000000;
`

export const Right = styled.div`
  display: flex;
  align-items: center;

  ${media.greaterThan('large')`
    flex: 1;
    justify-content: flex-end;
  `}
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;

  color: #000000;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #1f1f1f;
    margin-left: 4px;
    font-style: normal;
    font-weight: 600;
  }

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.03em;

    color: #000000;
    display: flex;
    justify-content: space-between;
  `}
`

export const ContractsLabels = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: initial !important;
  margin-right: 16px;
`

export const ErrorMessage = styled(Typography)`
  position: absolute;
  top: calc(100% - 2px);
  text-wrap: nowrap;
`

export const TitleLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.03em;

  color: #5f5f5f;
`

export const FirstValue = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;

  color: #000000;

  margin-right: 16px;
`

export const Payment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 39px 0px 61px;
`

export const ValueTotal = styled.div``

export const BackButton = styled.div`
  padding-top: 38px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100px;

  button {
    border: none;
  }
`
