import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Link, Logo } from '../../components'

const CreateMember = () => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Logo />
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        variant="porto-title-5-bold"
        content="Para criar uma conta de membro, é necessário convite"
      />
      <Typography
        variant="porto-text-body-1-regular"
        content={`
          Solicite acesso ao seu administrador.
        `}
      />
    </Flex>
  )
}

export default CreateMember
