import { Badge } from '@/components'
import TooltipError from '@/components/Utilities/TooltipError'
import { Contract } from '@/modules/contract/interface'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { useMemo } from 'react'

const parseError = {
  '@imob/integration-failure': {
    title:
      'Nosso servidor demorou para responder, clique em reenviar para calcularmos o orçamento deste proponente.',
  },
  '500': {
    title: 'Erro de integração.',
  },
  '@imob/discount': {
    title: 'Desconto técnico promocional não permitido.',
  },
} as const

export const ValidationTooltip: React.FC<{ contract: Contract<Products> }> = ({ contract }) => {
  const contentMessage = useMemo(() => {
    const parsedMessage = parseError[contract?.metadata?.error?.code || '500']?.title

    if (parsedMessage) return [parsedMessage].flat()

    const rawMessage = contract.metadata.error?.cause?.exception?.errors?.message

    return [rawMessage].flat()
  }, [contract.metadata.error?.cause?.exception?.errors?.message, contract.metadata.error?.code])

  // @ts-ignore
  if (contract.external_status === 'failure' || contract.external_status === 'critical') {
    return (
      <TooltipError
        background="white"
        status="Dados incorretos"
        content={contentMessage}
        backgroundArrow="white"
      >
        <Badge
          icon={
            {
              failure: 'alert-circle',
              critical: 'alert-circle',
            }[contract.external_status]
          }
          removeDot
          kind={
            {
              failure: 'danger',
              critical: 'danger',
            }[contract.external_status]
          }
        >
          {
            {
              failure: 'Falha na integração',
              critical: 'Crítica',
            }[contract.external_status]
          }
        </Badge>
      </TooltipError>
    )
  } else {
    return (
      <Badge
        icon={
          {
            processing: 'alert-circle',
            calculated: 'check',
            done: 'check',
          }[contract.external_status]
        }
        removeDot
        kind={
          {
            processing: 'warning',
            calculated: 'success',
            done: 'success',
          }[contract.external_status]
        }
      >
        {
          {
            processing: 'Em processamento',
            calculated: 'Orçamento calculado',
            done: 'Concluído',
          }[contract.external_status]
        }
      </Badge>
    )
  }
}
