import React, { Dispatch, ReactNode, useContext, useReducer } from 'react'
import { mergeWith } from 'lodash'

interface State {
  check: {
    hasAccount?: boolean
    hasAccreditation?: boolean
  }
  partnerLead: {
    document?: string
    name?: string
    email?: string
    phone?: string
    experienceMetadata?: {
      howDidYouHearAboutUs: {
        answer?: string
        other_answer?: string
      }
    }
  }
  partnerLeadResponse: {
    maskedEmail?: string
  }
  profileType?: 'REAL_ESTATE' | 'BROKER' | 'MEMBER'
}

export enum ActionTypes {
  UPDATE,
  RESET,
}

const initialState: State = {
  check: {
    hasAccount: undefined,
    hasAccreditation: undefined,
  },
  partnerLead: {
    document: undefined,
    name: undefined,
    email: undefined,
    phone: undefined,
    experienceMetadata: {
      howDidYouHearAboutUs: {
        answer: undefined,
        other_answer: undefined,
      },
    },
  },
  partnerLeadResponse: {
    maskedEmail: undefined,
  },
}

export const reducer = (
  state: typeof initialState,
  action: {
    type: ActionTypes
    payload: Partial<State>
  }
) => {
  switch (action.type) {
    case ActionTypes.UPDATE: {
      return mergeWith(state, action.payload)
    }

    case ActionTypes.RESET: {
      return initialState
    }
  }
}

const NewAccountContext = React.createContext<{
  state: typeof initialState
  dispatch: Dispatch<{
    type: ActionTypes
    payload: Partial<State>
  }>
}>({ state: initialState, dispatch: () => {} })
export const useNewAccount = () => useContext(NewAccountContext)

export const NewAccountProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <NewAccountContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </NewAccountContext.Provider>
  )
}
