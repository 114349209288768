import { ReactNode } from 'react'

import { Content } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Contract } from '@/modules/contract/interface'
import { usePagination } from '@/tools'
import { useHistory } from 'react-router-dom'
import { getContractKey, getContractOptions } from '../../data/table.data'
import { TableItem } from '../TableItem'
import * as S from './styles'

export type ITableColumn = {
  label: string
  small?: boolean
  render: (item: Contract<any>) => any
}

type ITableProps = {
  columns: ITableColumn[]
  getOptions: typeof getContractOptions
  getDataKey: typeof getContractKey
  renderFooter: () => ReactNode
}

export default function Table({ columns, getOptions, renderFooter }: ITableProps) {
  const history = useHistory()

  const {
    pushUpdate: setLoading,
    finishUpdate: finishLoading,
    updateContractData: updateContract,
    isLoading,
    contracts,
  } = usePagination()

  if (isLoading) return <Content.Loader message="Aguarde, buscando contratos." />

  if (contracts.length === 0) return <S.Message>Não há contratos a serem exibidos.</S.Message>

  return (
    <S.Container data-test-id="container">
      <S.Grid data-test-id="contratos">
        {columns.map(column => (
          <S.Head key={column.label}>
            <Typography content={column.label} variant="porto-text-body-2-bold" />
          </S.Head>
        ))}

        <S.Head />

        {contracts.map((item, index) => (
          <TableItem
            key={item.id}
            color={index % 2 ? 'system-surface-secondary' : 'system-surface-primary'}
            columns={columns}
            contract={item}
            options={getOptions(item, history, {
              setLoading,
              finishLoading,
              updateContract,
            })}
          />
        ))}
      </S.Grid>
      {renderFooter()}
    </S.Container>
  )
}
