import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'

import { IRootState } from '@/store'

import {
  setAnalyticsPage,
  setAnalyticsSite,
  setAnalyticsUser,
} from '@/store/ducks/analytics'

import {
  EAnalyticsBrand,
  EAnalyticsPortal,
  EAnalyticsProducts,
} from '@/store/ducks/analytics/interfaces'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Forms, toast } from '@/components'
import { handleLogin } from '@/modules/auth/actions/auth'
import { useGTM } from '@/tools'

import { Base, Logo, NeedHelp } from '../components'
import { Action, Divider, LinkLabel, Title } from './styles'
import Chat from '@/components/Chat'
import { isEmail } from '@/tools/validators'

const Login = () => {
  const { customData } = useSelector(({ analytics }: IRootState) => analytics)
  const [isLoading, setIsLoading] = useState(false)
  const [openChat, setOpenChat] = useState(false)

  const dispatch = useDispatch()
  const isMounted = useRef(false)

  const { setCustomData, setDataLayer } = useGTM()

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required('Campo E-mail é obrigatório.')
        .test(
          'email-test',
          'Por favor insira um email válido.',
          (value) => !!value && isEmail(value)
        ),
      password: yup.string().required('Campo senha é obrigatório.'),
    }),
    onSubmit: async ({ email, password }) => {
      setIsLoading(true)

      try {
        setCustomData(customData)
        await handleLogin({ email, password })

        setDataLayer({
          event: 'solicitacao_servico',
          nome_servico: 'entrar',
          tipo_servico: 'login',
          retorno: 'sucesso',
          descricao: 'usuario-adicionar-senha',
        })
      } catch (e) {
        setDataLayer({
          event: 'solicitacao_servico',
          nome_servico: 'entrar',
          tipo_servico: 'login',
          retorno: 'erro',
          descricao: 'usuario-adicionar-senha',
          erro: {
            codigo: e.response?.status,
            servico: 'login-auth',
            mensagem: 'dados inválidos',
          },
        })
        toast(
          <>
            <b>E-mail e senha não correspondem.</b>
            <br />
            <br />
            <p>
              Verifique se digitou corretamente ou acesse "Esqueci minha senha".
            </p>
          </>,
          {
            type: 'error',
            delay: 5000,
          }
        )
      }

      setIsLoading(false)
    },
  })

  const handleAnalytics = () => {
    const dataLayer = {
      event: 'step_change',
      titulo: 'portal imobiliaria - login',
      etapa: '/login-email',
    }

    dispatch(
      setAnalyticsPage({
        name: 'Portal Imobiliária - Login',
        product: EAnalyticsProducts.aluguelFianca,
      })
    )

    dispatch(
      setAnalyticsSite({
        brand: EAnalyticsBrand.portoseguro,
        portal: EAnalyticsPortal.imobiliarias,
      })
    )

    dispatch(
      setAnalyticsUser({
        logado: false,
        protocolo: '',
        susep: '',
        user_id: '',
      })
    )

    setDataLayer(dataLayer)
  }

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true

      handleAnalytics()
    }
  }, [])

  return (
    <Base>
      <Logo />
      <Title
        color="system-primitive-neutral-900"
        variant="porto-title-5-medium"
        content="Acesse sua conta"
      />
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Flex
            sx={{
              flexDirection: 'column',
              marginTop: '2rem',
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name="email"
              label="E-mail"
              placeholder="nome@email.com.br"
              data-test-id="email"
            />
            <Forms.InputGroup
              name="password"
              label="Senha"
              placeholder="Digite"
              type="password"
              data-test-id="password"
            />
          </Flex>
          <Box
            sx={{
              marginTop: '1rem',
            }}
          >
            <Link to="/esqueci-senha">
              <LinkLabel
                color="system-text-link"
                data-gtm-clicktype="link"
                data-gtm-name="esqueci-minha-senha"
                data-gtm-type="click"
                variant="porto-text-link-body-2-bold"
                content="Esqueci minha senha"
                data-test-id="recover-password"
              />
            </Link>
          </Box>
          <Box
            sx={{
              marginTop: '2rem',
            }}
          >
            <Action
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="entrar"
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
              data-test-id="enter"
            >
              Entrar
            </Action>
          </Box>
        </form>
      </FormikProvider>
      <Divider />
      <Flex
        sx={{
          alignItems: 'center',
          gap: '.5rem',
          marginBottom: '8px',
        }}
      >
        <Typography
          color="system-primitive-neutral-900"
          variant="porto-body-1-bold"
          content="Sua organização não tem uma conta?"
        /> 
      </Flex>
      <Flex
        sx={{
          alignItems: 'center',
          gap: '.5rem',
          marginBottom: '32px',
        }}
      >
        <Link to="/primeiro-acesso">
          <LinkLabel
            data-gtm-clicktype="link"
            data-gtm-name="crie-sua-conta"
            data-gtm-type="click"
            color="system-text-link"
            variant="porto-text-link-body-1-bold"
            content="Crie sua conta"
            data-test-id="first-access"
          />
        </Link>
      </Flex>
      <NeedHelp callback={() => setOpenChat(true)} />
      <Chat open={openChat} />
    </Base>
  )
}

export default Login
