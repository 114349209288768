/* eslint-disable */
import { usePartnershipQuery } from '@/tools/hooks/partnership'

export const useIsEssencialEnabled = (profile: {
  type: string
}): { result: boolean; isLoading: boolean } => {
  if (profile.type === 'BROKER') return { result: true, isLoading: false }

  const partnershipsQuery = usePartnershipQuery({ partner: profile.id })

  const isEnabled = partnershipsQuery.data?.some(
    partnership => partnership.settings['porto_essencial.enabled'],
  )

  return { result: isEnabled, isLoading: partnershipsQuery.isFetching }
}

export const isBroker = profile => profile.type === 'BROKER'
