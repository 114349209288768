import { generateRangeNumbers } from '@/tools'

import { commission, options, prolabore } from '../commons'

export default {
  'porto_imobiliaria.residential.enabled': {
    type: 'toggle',
    title: 'Plano residencial',
    description: 'Habilite ou desabilite o plano residencial',
  },

  'porto_imobiliaria.residential.coverages': {
    type: 'checkbox',
    title: 'Coberturas',
    description: 'Selecione as coberturas disponíveis.',
    disabled: ({ getValue }) => !getValue('porto_imobiliaria.residential.enabled'),
    options: options('porto_imobiliaria.residential.coverages', [
      {
        value: 'fire',
        label: 'Incêndio',
        disabled: ({ user }) => !user.store.isAdmin,
      },
      {
        value: 'content',
        label: 'Conteúdo',
        disabled: ({ user }) => !user.store.isAdmin,
      },
      {
        value: 'eletrical_damages',
        label: 'Danos elétricos',
      },
      {
        value: 'vehicle_impacts',
        label: 'Impacto de veículos',
      },
      {
        value: 'gale',
        label: 'Vendaval',
      },
      {
        value: 'civil_responsability',
        label: 'Responsabilidade civil',
      },
      {
        value: 'rental_loss',
        label: 'Perda de aluguel',
      },
    ]),
  },
  'porto_imobiliaria.residential.service_clauses': {
    type: 'checkbox',
    title: 'Cláusulas de serviço',
    description: 'Escolhe quais estarão ativas.',
    disabled: ({ getValue }) => !getValue('porto_imobiliaria.residential.enabled'),
    options: options('porto_imobiliaria.residential.service_clauses', [
      {
        value: '479',
        label: 'Padrão gratuito',
        disabled: ({ user }) => !user.store.isAdmin,
      },
      {
        value: '553',
        label: 'Intermediário rede referenciada',
      },
      {
        value: '485',
        label: 'Intermediário livre escolha',
      },
      {
        value: '480',
        label: 'Total rede referenciada',
      },
      {
        value: '548',
        label: 'Total livre escolha',
      },
    ]),
  },
  'porto_imobiliaria.residential.comission': commission(
    generateRangeNumbers(10, 70),
    ({ getValue }) => !getValue('porto_imobiliaria.residential.enabled'),
  ),
  'porto_imobiliaria.residential.prolabore': prolabore(
    'porto_imobiliaria.residential.enabled',
    'porto_imobiliaria.residential.prolabore',
    'porto_imobiliaria.residential.comission',
  ),
}
