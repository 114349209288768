import { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'

import { Forms, Grid, Content, Conditional, Tooltip } from '@/components'

import { ImobiliariaBudget } from '..'
import * as S from '../styles'

const Periods = () => {
  const { state: contract } = useLocation<any>()
  const formik = useFormikContext<ImobiliariaBudget>()
  const isRenewal = !!formik.values.renewal_number

  const validatePeriodRenewalContract = () => {
    const today = new Date()

    if (!isRenewal) return today

    const contractEnd = new Date(
      dayjs(contract?.payload?.contract.period.start).format()
    )
    const dayLimit = new Date(dayjs(contractEnd).add(5, 'day').format())

    if (dayjs(today).isAfter(dayLimit, 'day')) {
      return today
    }

    return contractEnd
  }

  const startDate = useMemo(
    () => formik.values.contract_period_start,
    [formik.values.contract_period_start]
  )

  const vacantEstate = useMemo(
    () => ['7', '8', '4'].includes(formik.values.estate_type),
    [formik.values.estate_type]
  )

  const maxStartDate = new Date(
    dayjs(contract?.payload?.contract.period.start)
      .add(!isRenewal ? 2 : 1, 'month')
      .format()
  )

  const validateDate = (period: string) => {
    return new Date(
      dayjs(formik.values.contract_period_start)
        .add(period === 'minEnd' ? 1 : 5, 'year')
        .format()
    )
  }

  useEffect(() => {
    const contractPeriodEnd = new Date(dayjs(contract?.payload?.contract.period.end).format())

    if (isRenewal) {
      formik.setFieldValue(
        'contract_period_end',
        contractPeriodEnd,
      )
    } else if (
      startDate &&
      validateDate('minEnd') > contractPeriodEnd
    ) {
      const end = new Date(
        dayjs(startDate)
          .add(vacantEstate ? 6 : 12, 'month')
          .format(),
      )

      formik.setFieldValue('contract_period_end', end)
    }
  }, [startDate, formik.values.estate_type])

  useEffect(() => {
    const start = !isRenewal
      ? new Date()
      : validatePeriodRenewalContract()
    formik.setFieldValue('contract_period_start', start)
  }, [])

  return (
    <Forms.Card
      step={isRenewal ? '5' : '6'}
      title="Vigência"
      helper="E por fim, defina as datas de <b>início</b> e <b>fim</b> vigência do contrato."
      active
    >
      <S.Grid>
        <Forms.Datepicker
          name="contract_period_start"
          label="Início de vigência"
          placeholderText="Digite a data inicial"
          minDate={validatePeriodRenewalContract()}
          maxDate={maxStartDate}
          readOnly={isRenewal}
          inputProps={{
            'data-gtm-type': 'select',
            'data-gtm-name': 'inicio-de-vigencia',
            'data-gtm-subname': 'vigencias',
          }}
        />
        <S.DatepickerWrapper>
          <Forms.Datepicker
            name="contract_period_end"
            label="Fim de vigência"
            placeholderText="Digite a data final"
            minDate={validateDate('minEnd')}
            maxDate={validateDate('maxEnd')}
            readOnly={vacantEstate || isRenewal}
            inputProps={{
              'data-gtm-type': 'select',
              'data-gtm-name': 'fim-de-vigencia',
              'data-gtm-subname': 'vigencias',
            }}
          />
          <S.TooltipWrapper>
            <Tooltip
              placement="top"
              content="A vigência mínima do seguro é de 356 dias (1 ano) e a vigência máxima é de 5 anos."
            >
              <S.Icon name="info" />
            </Tooltip>
          </S.TooltipWrapper>
        </S.DatepickerWrapper>
      </S.Grid>

      <Conditional when={vacantEstate}>
        <Grid space={['1rem 0 0', '2rem 0 0']}>
          <Content.Message title="Atenção" kind="warning">
            Para imóveis desocupados a vigência máxima é de <b>6 meses</b>.
          </Content.Message>
        </Grid>
      </Conditional>
    </Forms.Card>
  )
}

export default Periods
