import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { Actions } from '@/components'

const Link = ({
  label,
  icon,
  to,
}: {
  label: string
  icon?: string
  to: string
}) => {
  return (
    <Actions.Link to={to}>
      <Flex
        sx={{
          alignItems: 'center',
          gap: '.5rem',
          marginTop: '16px',
        }}
      >
        {icon && <Icon name={icon} color="system-text-highlight" />}
        <Typography
          variant="porto-text-body-1-regular"
          color="system-text-highlight"
          content={label}
        />
      </Flex>
    </Actions.Link>
  )
}

export default Link
