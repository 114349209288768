import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'

import { Forms, Grid, Modals, toast } from '@/components'
import { api } from '@/services'

import { Container } from './styles'
import validationSchema from './validations'

type Props = Modals.Promised.PromisedModalProps

const PasswordChangeModal = (props: Props) => {
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async ({ email, password }) => {
    try {
      setLoading(true)

      await api.instance.v2.put(`/users`, {
        currentEmail: email,
        password,
      })

      props.onSubmit({ success: true })

      toast('Senha alterado com sucesso!.', {
        type: 'success',
      })
    } catch (error) {
      toast('Ocorreu um erro ao alterar a senha.', {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <Modals.Content
      icon="key-outline"
      title="Cadastrar nova senha"
      onCancel={{ text: 'Cancelar', onClick: () => props.onDismiss() }}
      onSubmit={{
        text: 'Confirmar alteração',
        onClick: () => form.handleSubmit(),
      }}
      loader={{
        message: 'A senha está sendo alterada.',
        status: isLoading,
      }}
    >
      <Container>
        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit}>
            <Grid gap="2rem">
              <Forms.InputGroup
                label="Endereço de e-mail"
                type="email"
                placeholder="email@exemplo.com"
                name="email"
              />
              <Forms.InputGroup
                label="Nova senha"
                type="password"
                placeholder="insira aqui a nova senha"
                name="password"
              />
            </Grid>
          </form>
        </FormikProvider>
      </Container>
    </Modals.Content>
  )
}

PasswordChangeModal.open = Modals.Promised.createModal<Props>(PasswordChangeModal)

export default PasswordChangeModal
