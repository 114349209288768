import { Card, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Grid } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export type ContractErrorsProps = {
  icon: string
  title: string
  errors: Array<{label: string, description: string}>
}

export const ContractErrors: React.FC<ContractErrorsProps> = ({ icon, title, errors }) => {
  return (
    <Card
      sx={{
        background: '#FFCDC6',
        border: 0,
      }}
    >
      <Grid gap="12px" columns="max-content 1fr">
        <Icon name={icon} color="#97453C" />
        <Grid>
          <Typography as="span" variant="porto-text-body-1-bold" color="system-text-primary">
            {title}
          </Typography>
          {errors.map((error, index) => (
            <Typography as="span" variant="porto-text-body-2-regular" color="system-text-secondary">
              {error.label}: {error.description}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}