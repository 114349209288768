import { isAfter, isBefore, parse } from 'date-fns'

export const isBeforeToday = (value?: string) => {
  if (!value) return false

  const today = new Date()
  const date = parse(
    value.substring(0, 10).split('-').reverse().join('/'),
    'dd/MM/yyyy',
    new Date(),
  )

  return isBefore(date, today)
}

export const isAfterToday = (value?: string) => {
  if (!value) return false

  const today = new Date()
  const date = parse(
    value.substring(0, 10).split('-').reverse().join('/'),
    'dd/MM/yyyy',
    new Date(),
  )

  return isAfter(date, today)
}
