import { api } from '@/services'
import { useQuery, UseQueryOptions } from 'react-query'

type Props = { broker?: string; partner: string } | { broker: string; partner?: string }

export function usePartnershipQuery({ partner, broker }: Props, options?: UseQueryOptions) {
  const partnershipsQuery = useQuery(
    ['organizations.partnerships.list', `broker-${broker}`, `partner-${partner}`],
    () =>
      api.organizations.partnerships.list({
        broker,
        partner,
      }),
    {
      enabled: Boolean(partner || broker),
      refetchOnWindowFocus: false,
      ...options,
    }
  )

  return partnershipsQuery
}
