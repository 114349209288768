import { Actions, Autocomplete, Forms, Grid, Icons } from '@/components'
import { getOccupations } from '@/services/api/capitalizacao'

import Button from '@/components/Actions/Button'
import { ISelectGroupOption } from '@/components/Forms/SelectGroup/interfaces'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { SociosCapitalizacao } from '@/data/capitalizacao/forms/schemas/firstStepSchema'
import { api } from '@/services'
import { useFeatureEnable } from '@/tools'
import { useCountries } from '@/tools/hooks/capitalizacao/useCountries'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { isCNPJ, isCPF, parseToNumber } from 'brazilian-values'
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { BaseCard } from '../../../../components/BaseCard'
import * as S from './styles'

const Socios: React.FC<
  {
    index: number
  } & FieldArrayRenderProps
> = ({ index, push, remove, form, name: arrayFieldName }) => {
  const [partnersField, partnersFieldMeta] = useField<SociosCapitalizacao[]>(arrayFieldName)
  const [documentField, documentMeta] = useField(`${arrayFieldName}[${index}].document`)
  const [, stakeMeta] = useField(`${arrayFieldName}[${index}].stake`)
  const [zipcodeField, zipcodeMeta] = useField(`${arrayFieldName}[${index}].address.zipcode`)

  const socioPosition = index + 1
  const showPositionsLabels = partnersField.value?.length > 1
  const lastPosition = partnersField.value?.length === socioPosition

  const fetchCustomerMutation = useMutation(async () => {
    if (!documentField.value || !!documentMeta.error) throw new Error('Documento inválido')

    const customer = await api.customers.getByDocument(documentField.value)

    await Promise.all([
      form.setFieldValue(
        `${arrayFieldName}[${index}].name`,
        customer.name || customer.companyName || '',
      ),
    ])
  })

  const fetchAddressMutation = useMutation(async () => {
    if (!zipcodeField.value || !!zipcodeMeta.error) throw new Error('Endereço inválido')

    const address = await api.addresses.getByZipcode(zipcodeField.value)

    await Promise.all([
      form.setFieldValue(`${arrayFieldName}[${index}].address.state`, address?.state),
      form.setFieldValue(`${arrayFieldName}[${index}].address.city`, address?.city),
      form.setFieldValue(`${arrayFieldName}[${index}].address.neighborhood`, address?.neighborhood),
      form.setFieldValue(`${arrayFieldName}[${index}].address.street`, address?.street),
    ])

    return address
  })

  const disabledAdd =
    !!partnersFieldMeta.error ||
    partnersField.value.reduce((currentValue, socio) => {
      const numericValue = parseToNumber(socio?.stake?.replace('%', '') || '0')

      return currentValue + numericValue
    }, 0) >= 100

  return (
    <Grid gap="16px">
      <Grid columns={['1fr', 'repeat(2, max-content)']} gap="16px">
        <Typography variant="porto-title-6-medium">
          Sócio cedente {showPositionsLabels && socioPosition}
        </Typography>

        {partnersField.value?.length > 1 && (
          <Actions.Link
            as="button"
            type="button"
            variant="danger"
            kind="ghost"
            onClick={() => remove(index)}
            data-test-id="partner-remove"
          >
            Remover sócio
          </Actions.Link>
        )}
      </Grid>

      <Grid gap="16px" columns={['1fr', '1fr', '1fr 2fr']}>
        <Forms.InputGroup
          label="CPF/CNPJ"
          name={`${arrayFieldName}[${index}].document`}
          mask="document"
          placeholder="000.000.000-00"
          onBlur={() => fetchCustomerMutation.mutate()}
          loading={fetchCustomerMutation.isLoading}
          readOnly={fetchCustomerMutation.isLoading}
          data-test-id="partner-document"
        />
      </Grid>
      <Grid gap="16px" columns={['1fr', '1fr', '2fr 1fr']}>
        <Forms.InputGroup
          label="Nome completo"
          name={`${arrayFieldName}[${index}].name`}
          placeholder="Digite"
          data-test-id="partner-name"
        />
      </Grid>
      <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
        <Forms.SelectGroup
          resetValueOnUnmount={false}
          label="Papel de sociedade"
          name={`${arrayFieldName}[${index}].role`}
          options={[
            { label: 'Selecione', value: '' },
            { label: 'Administrador', value: 39 },
            { label: 'Controlador', value: 30 },
            { label: 'Procurador', value: 24 },
          ]}
          data-test-id="partner-role-society"
        />
        <Forms.InputGroup
          label="Participação (%)"
          name={`${arrayFieldName}[${index}].stake`}
          mask="percentage"
          min="0"
          max="100"
          placeholder="0,01%"
          data-test-id="partner-participation"
        />
      </Grid>

      <Typography variant="porto-title-6-medium">Endereço do sócio cedente</Typography>
      <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
        <Forms.SelectGroup
          resetValueOnUnmount={false}
          label="Tipo de imóvel"
          name={`${arrayFieldName}[${index}].address.type`}
          options={[
            { label: 'Selecione', value: '' },
            { label: 'Residencial', value: 'RESIDENCIAL' },
            { label: 'Comercial', value: 'COMERCIAL' },
          ]}
          data-test-id="partner-tipo-property"
        />
        <Forms.InputGroup
          label="CEP"
          name={`${arrayFieldName}[${index}].address.zipcode`}
          placeholder="00000-000"
          mask="zipcode"
          onBlur={() => fetchAddressMutation.mutate()}
          loading={fetchAddressMutation.isLoading}
          readOnly={fetchAddressMutation.isLoading}
          data-test-id="partner-cep"
        />
      </Grid>

      <AnimateVerticalShow
        isOpen={!fetchAddressMutation.isLoading && fetchAddressMutation.isSuccess}
      >
        <Grid gap="16px">
          <Grid gap="16px" columns={['1fr', '1fr', 'repeat(2, 1fr)']}>
            <Forms.InputGroup
              label="Endereço"
              name={`${arrayFieldName}[${index}].address.street`}
              placeholder="Digite"
              readOnly={fetchAddressMutation.isSuccess && fetchAddressMutation.data.street}
              data-test-id="partner-address-full"
            />
            <Grid gap="16px" columns="1fr 2fr">
              <Forms.InputGroup
                label="Número"
                name={`${arrayFieldName}[${index}].address.number`}
                placeholder="Digite"
                data-test-id="partner-address-number"
              />
              <Forms.InputGroup
                label="Complemento"
                name={`${arrayFieldName}[${index}].address.complement`}
                placeholder="Digite"
                data-test-id="partner-address-complement"
              />
            </Grid>
          </Grid>
          <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
            <Forms.InputGroup
              label="Bairro"
              name={`${arrayFieldName}[${index}].address.neighborhood`}
              placeholder="Digite"
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.neighborhood}
              data-test-id="partner-address-neighborhood"
            />
            <Forms.InputGroup
              label="Cidade"
              name={`${arrayFieldName}[${index}].address.city`}
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.city}
              data-test-id="partner-address-city"
            />
            <Forms.InputGroup
              label="Estado"
              name={`${arrayFieldName}[${index}].address.state`}
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.state}
              data-test-id="partner-address-state"
            />
          </Grid>
        </Grid>
      </AnimateVerticalShow>

      {!!stakeMeta.touched && typeof form.errors.stake === 'string' && lastPosition && (
        <Card
          sx={{
            background: 'var(--system-surface-warning)',
            border: 0,
          }}
        >
          <Grid columns={'max-content 1fr'} gap="8px">
            <Icons.FeatherIcons color="var(--porto-primitive-yellow-100)" name="info" />
            <Typography color="var(--porto-primitive-yellow-100)" variant="">
              {form.errors.stake}
            </Typography>
          </Grid>
        </Card>
      )}

      <Grid columns={['1fr', 'max-content']}>
        {lastPosition && (
          <Button
            data-test-id="partner-new"
            isDisabled={disabledAdd}
            kind="ghost"
            type="button"
            onClick={() => push('')}
          >
            Adicionar sócio cedente
          </Button>
        )}
      </Grid>

      {!lastPosition && <S.Divisor />}
    </Grid>
  )
}

export const Tenant = ({ enums }) => {
  const { setFieldValue } = useFormikContext()

  const { value: documentType } = useField('customer.complement_document_type_id')[0]
  const [{ value: customerValue }, , { setValue: setCustomerValue }] = useField('customer')
  const [documentField, documentFieldState] = useField<string>('customer.document')
  const [stateRegistrationIsentField] = useField<boolean>('customer.stateRegistrationIsent')

  const { value: pepValue } = useField('customer.pep.expose_person_flag')[0]
  const { value: occupationValue } = useField('customer.occupation')[0]

  const [showForm, setShowForm] = useState(documentFieldState.touched)

  const getCustomerMutation = useMutation(async () => {
    try {
      if (documentFieldState.error) return

      const results = await api.customers.getByDocument(documentField.value).catch(() => {})
      const hasPartnersOnBureau = isCNPJ(documentField.value) && !!results?.hasPartnersOnBureau

      setCustomerValue({
        ...customerValue,
        name: results?.name || results?.companyName,
        phone: results?.phone,
        birthdate: results?.birthdate || results?.foundation_date,
        hasPartnersOnBureau,
        socios: isCNPJ(documentField.value) && !hasPartnersOnBureau ? [''] : null,
      })
    } finally {
      setShowForm(true)
    }
  })

  const formToShow = useMemo(() => {
    if (!showForm) return
    if (documentFieldState.error) return
    if (!documentFieldState.touched) return
    if (getCustomerMutation.isLoading) return

    if (isCPF(documentField.value)) return 'PF'

    if (isCNPJ(documentField.value)) return 'PJ'

    return
  }, [
    documentField.value,
    documentFieldState.error,
    documentFieldState.touched,
    getCustomerMutation.isLoading,
    showForm,
  ])

  const featureEnable = useFeatureEnable()

  const incomeBracketId = useMemo(() => {
    const options: ISelectGroupOption[] =
      enums.incomeBracket?.filter(
        ({ value }) => value !== 'NO_INCOME' && value !== 'DO_NOT_WISH_TO_INFORM',
      ) || []

    options.unshift({ label: 'Selecione', value: '' })

    const defaultValue = options[0]?.value

    return { options, default: defaultValue }
  }, [enums.incomeBracket])

  useEffect(() => {
    if (customerValue?.strange === '0') setFieldValue('customer.nationalityId', 5)
  }, [customerValue?.strange, setFieldValue])

  useEffect(() => {
    if (stateRegistrationIsentField.value) setFieldValue('customer.stateRegistration', '')
  }, [setFieldValue, stateRegistrationIsentField.value])

  const countries = useCountries()

  const countriesOptions = useMemo(
    () =>
      countries.data?.map(country => ({
        label: capitalize(country.name),
        value: country.id,
      })),
    [countries.data],
  )

  const disabledNationalityId = useMemo(
    () => !Number(customerValue?.strange),
    [customerValue?.strange],
  )

  return (
    <BaseCard title="Inquilino">
      <Grid gap="16px">
        <Typography content="Dados pessoais do inquilino." variant="porto-title-6-medium" />

        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="16px">
          <Forms.InputGroup
            name="customer.document"
            label="CPF/CNPJ"
            placeholder="300.300.300-30"
            mask="document"
            showRequired
            onChange={() => setShowForm(false)}
            onBlur={() => getCustomerMutation.mutate()}
            readOnly={getCustomerMutation.isLoading}
            loading={getCustomerMutation.isLoading}
            data-test-id="consumer-document"
          />
        </Grid>

        <AnimateVerticalShow isOpen={formToShow === 'PJ'}>
          <Grid gap="16px">
            <Grid columns={['1fr', '1fr', '2fr 1fr']} gap="16px">
              <Forms.InputGroup
                name="customer.name"
                label="Razão social"
                placeholder="Digite"
                showRequired
                data-test-id="consumer-company-name"
              />
            </Grid>

            <Grid columns={['1fr', '1fr 2fr']} gap="16px">
              <Forms.InputGroup
                label="Telefone ou celular"
                name="customer.phone"
                showRequired
                mask="phone"
                placeholder="(00) 90000-0000"
                data-test-id="consumer-phone"
              />
              <Forms.InputGroup
                label="E-mail"
                name="customer.email"
                showRequired
                type="email"
                placeholder="exemplo@portoseguro.com.br"
                data-test-id="consumer-email"
              />
            </Grid>

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']} gap="16px">
              <Forms.InputGroup
                name="customer.municipalRegistration"
                label="Inscrição Municipal (somente números)"
                placeholder="Digite"
                mask="onlyNumbers"
                data-test-id="consumer-municipal-registration"
              />
              <Forms.InputGroup
                name="customer.stateRegistration"
                label="Inscrição Estadual (somente números)"
                placeholder="Digite"
                mask="onlyNumbers"
                readOnly={stateRegistrationIsentField.value}
                data-test-id="consumer-state-registration"
              />
              <Forms.Choice
                name="customer.stateRegistrationIsent"
                type="checkbox"
                label="Isenção de Inscrição Estadual"
                data-test-id="consumer-exemption-municipal-registration"
              />
              <Forms.SelectGroup
                name="customer.strange"
                label="Empresa estrangeira"
                defaultValue="0"
                options={[
                  {
                    label: 'Não',
                    value: '0',
                  },
                  {
                    label: 'Sim',
                    value: '1',
                  },
                ]}
                data-test-id="consumer-foreign-company"
              />

              <Forms.SelectGroup
                name="customer.nationalityId"
                label="País de origem"
                disabled={disabledNationalityId}
                defaultValue={5}
                options={countriesOptions}
                data-test-id="consumer-country-origin"
              />
            </Grid>

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
              <Forms.InputGroup
                name="customer.revenue"
                mask="money"
                placeholder="R$ 40.000"
                label="Faturamento mensal"
                showRequired
                data-test-id="consumer-monthly-billing"
              />
            </Grid>

            {!customerValue.hasPartnersOnBureau && (
              <FieldArray
                name="customer.socios"
                render={helpers =>
                  helpers.form.values.customer?.socios?.map((_, index) => {
                    return <Socios key={`partner-${index}`} index={index} {...helpers} />
                  })
                }
                data-test-id="consumer-partners"
              />
            )}
          </Grid>
        </AnimateVerticalShow>

        <AnimateVerticalShow isOpen={formToShow === 'PF'}>
          <Grid gap="16px">
            <Grid columns={['1fr', '1fr', '2fr 1fr']} gap="16px">
              <Forms.InputGroup
                name="customer.name"
                showRequired
                label="Nome Completo"
                placeholder="Digite"
                data-test-id="consumer-fullname"
              />
            </Grid>

            {featureEnable.socialNameProducts.socialNameCapitalizacao && (
              <AnimateVerticalShow isOpen={isCPF(customerValue.document)}>
                <SocialNameField textFieldName="customer.socialName" />
              </AnimateVerticalShow>
            )}

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']} gap="16px">
              <Forms.InputGroup
                name="customer.phone"
                showRequired
                label="Telefone ou Celular"
                placeholder="(00) 90000-0000"
                mask="phone"
                data-test-id="consumer-phone"
              />
              <Forms.InputGroup
                name="customer.email"
                label="E-mail"
                showRequired
                placeholder="nome@email.com.br"
                data-test-id="consumer-email"
              />
              <Forms.InputGroup
                name="customer.birthdate"
                label="Data de Nascimento"
                placeholder="dd/mm/aaaa"
                showRequired
                mask="date"
                data-test-id="consumer-date-birth"
              />
              <Autocomplete
                name="customer.occupation"
                label="Profissão"
                initialInnerValue={occupationValue}
                onSearch={async value => await getOccupations(value)}
                data-test-id="consumer-profession"
              />
              <Forms.SelectGroup
                name="customer.income_bracket_id"
                label="Faixa de renda"
                options={incomeBracketId.options}
                data-test-id="consumer-income-range"
              />
              <Forms.InputGroup
                name="customer.nationality"
                label="Nacionalidade"
                placeholder="Digite"
                data-test-id="consumer-nationality"
              />
            </Grid>

            <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
              <Forms.SelectGroup
                name="customer.civil_status"
                label="Estado Civil"
                defaultValue="SINGLE"
                options={enums.civilStatus}
                data-test-id="consumer-civil-status"
              />

              <Forms.SelectGroup
                name="customer.pep.expose_person_flag"
                label="Pessoa Exposta Politicamente (PEP)?"
                defaultValue="NO"
                options={enums.pep}
                data-test-id="consumer-politically-exposed-person"
              />
            </Grid>

            {pepValue === 'NO_BUT_CLOSE_RELATIONSHIP' && (
              <>
                <Typography content="Dados da pessoa relacionada" variant="porto-title-6-medium" />
                <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']} gap="16px">
                  <Forms.InputGroup
                    name="customer.pep.document"
                    label="CPF da PEP"
                    placeholder="000.000.000-00"
                    mask="cpf"
                    data-test-id="consumer-cpf-pep"
                  />
                  <Forms.InputGroup
                    name="customer.pep.name"
                    label="Nome Completo"
                    placeholder="Digite"
                    data-test-id="consumer-name-pep"
                  />
                  <Forms.SelectGroup
                    name="customer.pep.degree_of_kinship"
                    label="Grau de relacionamento"
                    defaultValue="OTHERS"
                    options={enums.pepKinship}
                    data-test-id="consumer-degree-relationship-pep"
                  />
                </Grid>
              </>
            )}

            <Typography content="Documento" variant="porto-title-6-medium" />

            <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
              <Forms.SelectGroup
                name="customer.complement_document_type_id"
                label="Documento"
                defaultValue="RG"
                options={enums.documentTypes}
                data-test-id="consumer-document-type"
              />

              {documentType === 'RNE' && (
                <Autocomplete
                  name="customer.country_complement_document_id"
                  label="País de origem do documento"
                  onSearch={value =>
                    enums.countries.filter(({ label }) =>
                      label.toLowerCase().includes(value.toLowerCase()),
                    )
                  }
                  data-test-id="consumer-country-origin-document"
                />
              )}
            </Grid>

            <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
              <Forms.InputGroup
                name="customer.complement_document"
                label="Número do documento"
                placeholder="Digite"
                data-test-id="consumer-document-number"
              />
              <Forms.InputGroup
                name="customer.complement_document_issuer"
                label="Órgão emissor"
                placeholder="Digite"
                data-test-id="consumer-issuing-body"
              />
              <Forms.InputGroup
                name="customer.complement_document_emission"
                label="Data de emissão"
                placeholder="dd/mm/aaaa"
                mask="date"
                data-test-id="consumer-date-issue"
              />
            </Grid>
          </Grid>
        </AnimateVerticalShow>
      </Grid>
    </BaseCard>
  )
}
