import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'

import { Conditional, Forms, Grid } from '@/components'

import { useContract } from '@/modules'
import { useDebounce, useFeatureEnable, useGTM } from '@/tools'
import { Action, Actions } from '../../Details/styles'
import { landlordSchema } from '../schema'

import { api } from '@/services'
import dayjs from 'dayjs'

import { isValidCNPJ, isValidCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { formatToCPFOrCNPJ, formatToPhone, isCEP, isCNPJ, isCPF } from 'brazilian-values'

import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import ErrorFocus from '@/tools/utils/focusError'
import { WizardContext } from 'react-albus'
import { useMutation } from 'react-query'
import { Products } from '../../../shared/entities'

const address = {
  city: '',
  state: '',
  number: '',
  street: '',
  zipcode: '',
  complement: '',
  neighborhood: '',
}

const useInitialValues = () => {
  const contract = useContract<Products.ESSENCIAL>()

  const landlord = contract.payload.landlord
  const main = contract.payload.landlord?.addresses.main
  const billing = contract.payload.landlord?.addresses.billing

  const initialValues = useMemo(
    () => ({
      document: formatToCPFOrCNPJ(landlord?.document || ''),
      name: landlord?.name || '',
      birthdate: landlord?.birthdate ? dayjs(landlord?.birthdate).format('DD/MM/YYYY') : '',
      email: landlord?.email || '',
      phone: !!landlord?.phone ? formatToPhone(landlord?.phone) : '',
      pep: landlord?.pep || '2',
      addresses: {
        useSameAddress: landlord?.addresses.useSameAddress ?? true,
        main: {
          zipcode: main?.zipcode || '',
          street: main?.street || '',
          number: main?.number || '',
          complement: main?.complement || '',
          neighborhood: main?.neighborhood || '',
          city: main?.city || '',
          state: main?.state || '',
        },
        billing: {
          zipcode: billing?.zipcode || '',
          street: billing?.street || '',
          number: billing?.number || '',
          complement: billing?.complement || '',
          neighborhood: billing?.neighborhood || '',
          city: billing?.city || '',
          state: billing?.state || '',
        },
      },
    }),
    [billing, landlord, main],
  )

  return initialValues
}

const Landlord: React.FC<WizardContext> = ({ previous }) => {
  const contract = useContract<Products.ESSENCIAL>()
  const { setCustomData, setDataLayer } = useGTM()

  const featureEnable = useFeatureEnable()

  const initialValues = useInitialValues()

  useEffect(() => {
    const budget = contract.budgets?.find(b => b.id === contract.payload?.payment?.budget)

    setCustomData({
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      page: {
        name: 'Portal Imobiliária - Orçamento - Seguro Fianca Essencial Resumo Locador',
        product: 'aluguel-fianca',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: typeof contract.partner === 'object' ? contract.partner.id : contract.partner,
        susep: typeof contract.broker === 'object' ? contract.broker.id : contract.broker,
        plano: budget?.description || '',
      },
    })
    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Seguro Fianca Essencial Resumo locador',
      etapa: '/area-logada/orcamentos/seguro-fianca-essencial/resumo-locador',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik({
    initialValues,
    validationSchema: landlordSchema,
    validateOnMount: true,
    onSubmit: async values => {
      try {
        const landlord = landlordSchema.cast(values)
        if (!!landlord.birthdate) {
          landlord.birthdate = landlord.birthdate.split('/').reverse().join('-')
        }
        landlord.document = landlord.document.replace(/\.|-|\//g, '')
        landlord.phone = landlord.phone.replace(/\D/g, '')

        await api.contracts.update(contract.id, { landlord })
        contract.update({ landlord })
      } catch (error) {
        console.error(error)
      }
    },
  })

  const useSameAddress = useDebounce(form.values.addresses.useSameAddress)

  const [status, setStatus] = useState<'fetchingCustomer' | 'loaded' | ''>('')
  const debouncedDocument = useDebounce(form.values.document)

  const addressMainMutate = useMutation(async () => {
    if (form.errors?.addresses?.main?.zipcode || !isCEP(form.values?.addresses?.main?.zipcode))
      throw new Error('CEP inválido')

    const address = await api.addresses
      .getByZipcode(form.values.addresses.main.zipcode)
      .catch(() => undefined)

    await Promise.all([
      form.setFieldValue('addresses.main.street', address?.street || '', false),
      form.setFieldValue('addresses.main.neighborhood', address?.neighborhood || '', false),
      form.setFieldValue('addresses.main.city', address?.city || '', false),
      form.setFieldValue('addresses.main.state', address?.state || '', false),
    ])

    return address
  })

  const showMainAddres = useMemo(() => {
    if (form.errors?.addresses?.main?.zipcode) return false

    if (addressMainMutate.isLoading) return false

    if (!addressMainMutate.isSuccess) return false

    if (
      addressMainMutate.isSuccess &&
      addressMainMutate.data?.zipcode !== form.values.addresses.main.zipcode
    )
      return false

    return true
  }, [
    addressMainMutate.data,
    addressMainMutate.isSuccess,
    addressMainMutate.isLoading,
    form.errors?.addresses?.main?.zipcode,
    form.values.addresses.main.zipcode,
  ])

  const addressBillingMutate = useMutation(async () => {
    if (
      form.errors?.addresses?.billing?.zipcode ||
      !isCEP(form.values?.addresses?.billing?.zipcode)
    )
      throw new Error('CEP inválido')

    const address = await api.addresses
      .getByZipcode(form.values.addresses.billing.zipcode)
      .catch(() => undefined)

    await Promise.all([
      form.setFieldValue('addresses.billing.street', address?.street || '', false),
      form.setFieldValue('addresses.billing.neighborhood', address?.neighborhood || '', false),
      form.setFieldValue('addresses.billing.city', address?.city || '', false),
      form.setFieldValue('addresses.billing.state', address?.state || '', false),
    ])

    return address
  })

  const showBillingAddres = useMemo(() => {
    if (form.errors?.addresses?.billing?.zipcode) return false

    if (addressBillingMutate.isLoading) return false

    if (!addressBillingMutate.isSuccess) return false

    if (
      addressBillingMutate.isSuccess &&
      addressBillingMutate.data?.zipcode !== form.values.addresses.billing?.zipcode
    )
      return false

    return true
  }, [
    addressBillingMutate.data,
    addressBillingMutate.isSuccess,
    addressBillingMutate.isLoading,
    form.errors?.addresses?.billing?.zipcode,
    form.values.addresses.billing?.zipcode,
  ])

  useEffect(() => {
    if (!debouncedDocument || form.errors.document) return

    setStatus('fetchingCustomer')

    if (isValidCPF(debouncedDocument)) {
      api.customers
        .getByDocument(debouncedDocument)
        .then(customer => {
          form.setFieldValue('name', customer.name)
          form.setFieldValue('phone', formatToPhone(customer.phone))
          form.setFieldValue('birthdate', dayjs(customer.birthdate).format('DD/MM/YYYY'))
        })
        .finally(() => setStatus('loaded'))
    } else if (isValidCNPJ(debouncedDocument)) {
      api.customers
        .getByDocument(onlyNumbers(debouncedDocument))
        .then(customer => {
          form.setFieldValue('name', customer.companyName)
          form.setFieldValue('phone', formatToPhone(customer.phone))
        })
        .finally(() => setStatus('loaded'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDocument])

  useEffect(() => {
    if (useSameAddress) form.setFieldValue('addresses.billing', address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSameAddress])

  useEffect(() => {
    if (!!contract.payload.landlord?.document) setStatus('loaded')

    // @ts-ignore
  }, [contract.product, contract.payload.landlord?.document])

  return (
    <Conditional when={contract.external_status !== 'PENDING'}>
      <FormikProvider value={form}>
        <Form>
          <Card>
            <Grid gap="16px">
              <div>
                <Typography
                  variant="porto-title-4-semibold"
                  content="Proprietário"
                  sx={{ display: 'block' }}
                />
                <Typography
                  variant="porto-text-body-1-regular"
                  color="system-primitive-neutral-700"
                  content="Os dados do proprietário não são compartilhados com o inquilino."
                  sx={{ display: 'block' }}
                />
              </div>
              <Typography
                variant="porto-title-6-medium"
                content="Dados pessoais do proprietário"
                sx={{ display: 'block', marginTop: '16px' }}
              />
              <Grid columns={['1fr', '1fr 1fr', '1fr 2fr']}>
                <Forms.InputGroup
                  name="document"
                  label="CPF/CNPJ"
                  placeholder="Digite o CPF/CNPJ"
                  mask="document"
                  loading={status === 'fetchingCustomer'}
                  readOnly={status === 'fetchingCustomer'}
                  data-test-id="cpf"
                />
              </Grid>
              <Grid columns={['1fr', '1fr 1fr', '2fr 1fr']}>
                <Forms.InputGroup
                  name="name"
                  label="Nome completo"
                  placeholder="Digite o nome completo"
                  data-test-id="full-name"
                />
              </Grid>
              {featureEnable.socialNameProducts.socialNameFiancaUnificada && (
                <AnimateVerticalShow isOpen={isCPF(debouncedDocument)}>
                  <SocialNameField textFieldName="socialName" toggleName="socialNameToggle" />
                </AnimateVerticalShow>
              )}
              <Grid columns={['1fr', '1fr', '1fr 2fr']} gap={['16px']}>
                <Forms.InputGroup
                  name="phone"
                  label="Telefone/celular"
                  placeholder="Digite o telefone/celular"
                  mask="phone"
                  data-test-id="phone"
                />

                <Forms.InputGroup
                  name="email"
                  label="E-mail"
                  placeholder="Digite o e-mail"
                  data-test-id="email"
                />

                <Forms.InputGroup
                  name="birthdate"
                  label={isCNPJ(form.values.document) ? 'Data de fundação' : 'Data de nascimento'}
                  placeholder={
                    isCNPJ(form.values.document)
                      ? 'Digite a data de nascimento'
                      : 'Digite a data de fundação'
                  }
                  mask="date"
                  data-test-id="birthdate"
                />

                <Forms.SelectGroup
                  name="pep"
                  label="Pessoa Politicamente Exposta"
                  defaultValue="2"
                  data-gtm-type="select"
                  data-gtm-name={{ '2': 'nao', '1': 'sim' }[String(form.values.pep)]}
                  data-gtm-subname="seguro-fianca-essencial-locador"
                  options={[
                    {
                      label: 'Não',
                      value: '2',
                    },
                    {
                      label: 'Sim',
                      value: '1',
                    },
                  ]}
                  data-test-id="pep"
                />
              </Grid>
              <Typography
                variant="porto-title-6-medium"
                content="Endereço do proprietário"
                sx={{ marginTop: '16px' }}
              />
              <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
                <Forms.InputGroup
                  name="addresses.main.zipcode"
                  label="CEP"
                  placeholder="Digite o CEP"
                  mask="zipcode"
                  onBlur={() => addressMainMutate.mutate()}
                  loading={addressMainMutate.isLoading}
                  data-test-id="cep"
                />
              </Grid>

              {showMainAddres && (
                <>
                  <Grid columns={['1fr', 'repeat(3, 1fr)', '3fr 1fr 1fr']} gap="16px">
                    <Forms.InputGroup
                      name="addresses.main.street"
                      label="Endereço"
                      placeholder="Digite"
                      type="text"
                      readOnly={addressMainMutate.isSuccess && !!addressMainMutate.data?.street}
                      data-test-id="street"
                    />
                    <Forms.InputGroup
                      name="addresses.main.number"
                      label="Número"
                      placeholder="Digite"
                      type="number"
                      data-test-id="number"
                    />
                    <Forms.InputGroup
                      name="addresses.main.complement"
                      label="Complemento"
                      placeholder="ex: ap 23"
                      data-test-id="complement"
                    />
                  </Grid>
                  <Grid columns={['1fr', '2fr 1fr 1fr', 'repeat(3, 1fr)']} gap="16px">
                    <Forms.InputGroup
                      name="addresses.main.neighborhood"
                      label="Bairro"
                      placeholder="Digite"
                      readOnly={
                        addressMainMutate.isSuccess && !!addressMainMutate.data?.neighborhood
                      }
                      data-test-id="neighborhood"
                    />
                    <Forms.InputGroup
                      name="addresses.main.city"
                      label="Cidade"
                      placeholder="Digite"
                      readOnly={addressMainMutate.isSuccess && !!addressMainMutate.data?.city}
                      data-test-id="city"
                    />
                    <Forms.InputGroup
                      name="addresses.main.state"
                      label="Estado"
                      placeholder="Digite"
                      readOnly={addressMainMutate.isSuccess && !!addressMainMutate.data?.state}
                      data-test-id="state"
                    />
                  </Grid>
                  <Forms.Toggle
                    name="addresses.useSameAddress"
                    label="Utilizar mesmo endereço para cobrança"
                    data-gtm-type="select"
                    data-gtm-name="utilizar-mesmo-endereco-para-cobranca"
                    data-gtm-subname="locador"
                  />
                </>
              )}

              {!form.values.addresses.useSameAddress && (
                <>
                  <Typography
                    variant="porto-title-6-medium"
                    content="Endereço de cobrança"
                    sx={{ marginTop: '16px' }}
                  />

                  <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
                    <Forms.InputGroup
                      name="addresses.billing.zipcode"
                      label="CEP de cobrança"
                      placeholder="Digite o CEP de cobrança"
                      mask="zipcode"
                      loading={addressBillingMutate.isLoading}
                      onBlur={() => addressBillingMutate.mutate()}
                    />
                  </Grid>

                  {showBillingAddres && (
                    <>
                      <Grid columns={['1fr', 'repeat(3, 1fr)', '3fr 1fr 1fr']} gap="16px">
                        <Forms.InputGroup
                          name="addresses.billing.street"
                          label="Endereço"
                          placeholder="Digite"
                          type="text"
                          readOnly={
                            addressBillingMutate.isSuccess && !!addressBillingMutate.data?.street
                          }
                        />
                        <Forms.InputGroup
                          name="addresses.billing.number"
                          label="Número"
                          placeholder="Digite"
                          type="number"
                        />
                        <Forms.InputGroup
                          name="addresses.billing.complement"
                          label="Complemento"
                          placeholder="ex: ap 23"
                        />
                      </Grid>
                      <Grid columns={['1fr', '2fr 1fr 1fr', 'repeat(3, 1fr)']} gap="16px">
                        <Forms.InputGroup
                          name="addresses.billing.neighborhood"
                          label="Bairro"
                          placeholder="Digite"
                          readOnly={
                            addressBillingMutate.isSuccess &&
                            !!addressBillingMutate.data?.neighborhood
                          }
                        />
                        <Forms.InputGroup
                          name="addresses.billing.city"
                          label="Cidade"
                          placeholder="Digite"
                          readOnly={
                            addressBillingMutate.isSuccess && !!addressBillingMutate.data?.city
                          }
                        />
                        <Forms.InputGroup
                          name="addresses.billing.state"
                          label="Estado"
                          placeholder="Digite"
                          readOnly={
                            addressBillingMutate.isSuccess && !!addressBillingMutate.data?.state
                          }
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Card>

          <Actions>
            <Action
              icon="ArrowLeft"
              onClick={previous}
              kind="smooth"
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="voltar"
              data-test-id="back"
            >
              Voltar
            </Action>

            <Action
              icon="ArrowRight"
              onClick={() => form.handleSubmit()}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="proxima-etapa"
              data-test-id="continue"
            >
              Próxima etapa
            </Action>
          </Actions>
        </Form>
        <ErrorFocus />
      </FormikProvider>
    </Conditional>
  )
}

export default Landlord
