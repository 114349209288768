import { Step, Steps, Wizard } from 'react-albus'

import Chat from '@/components/Chat'
import { useId } from 'react'
import { Base } from '../components'
import { NewAccountProvider } from './context'
import BrokerNotAccreditated from './steps/BrokerNotAccreditated'
import CreateMember from './steps/CreateMember'
import Document from './steps/Document'
import EmailSent from './steps/EmailSent'
import PersonalData from './steps/PersonalData'
import ProfileTypeSelector from './steps/ProfileTypeSelector'

const FirstAccess = () => {
  const brokerAccreditatedStep = useId()
  const createMemberStep = useId()
  const personalDataStep = useId()

  return (
    <Base>
      <NewAccountProvider>
        <Wizard
          render={(wizard: any) => (
            <Steps>
              <Step
                id="document"
                render={() => <Document wizard={wizard} hasAccreditationStep={personalDataStep} />}
              />
              <Step
                id="profile-type-selector"
                render={() => (
                  <ProfileTypeSelector
                    wizard={wizard}
                    partnerAccreditatedStep={personalDataStep}
                    createMemberStep={createMemberStep}
                    brokerAccreditatedStep={brokerAccreditatedStep}
                  />
                )}
              />
              <Step id={createMemberStep} render={() => <CreateMember />} />
              <Step id={brokerAccreditatedStep} render={() => <BrokerNotAccreditated />} />
              <Step id={personalDataStep} render={() => <PersonalData wizard={wizard} />} />
              <Step id="email-sent" render={() => <EmailSent />} />
            </Steps>
          )}
        />
      </NewAccountProvider>
      <Chat />
    </Base>
  )
}

export default FirstAccess
