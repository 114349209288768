import { isValidDate } from '@/tools'
import * as yup from 'yup'

export const dateStart = new Date()
dateStart.setMonth(dateStart.getMonth() - 6)

export const dateEnd = new Date()

export const initialValues = {
  dateStart,
  dateEnd,
  search: '',
}

export type FormValues = {
  search?: string
} & typeof initialValues

export const schema = yup.object({
  search: yup.string(),
  dateStart: yup
    .date()
    .typeError('Data inicial é obrigatória, verifique se o valor informado é uma data.')
    .required('Data inicial é obrigatória')
    .test(
      'validateStartAndEnd',
      'A data inicial deve ser menor que a data final',
      function (value) {
        const { dateEnd } = this.parent
        if (!isValidDate(value) || !isValidDate(dateEnd)) return true
        return new Date(value) <= new Date(dateEnd)
      },
    ),
  dateEnd: yup
    .date()
    .typeError('Data inicial é obrigatória, verifique se o valor informado é uma data.')
    .required('Data final é obrigatória')
    .test(
      'validateStartAndEnd',
      'A data final deve ser maior que a data inicial',
      function (value) {
        const { dateStart } = this.parent
        if (!isValidDate(value) || !isValidDate(dateStart)) return true
        return new Date(value) >= new Date(dateStart)
      },
    ),
})
