import {
  BoxCards,
  Faq,
  AlsoOffer,
  Comparison,
  Header,
  Anchors,
  InfoAndActionSection,
} from '@/modules/static-page/components'

export const useFiancaContent = () => {
  const fiancaEssencialContent = [
    {
      Component: Header,
      props: {
        title: 'Fiança Locatícia',
        subtitle: 'Mais segurança e agilidade pra fechar negócio!',
        description:
          'O Fiança Locatícia é um seguro completo que substitui o fiador ou caução e ainda tem uma série de coberturas adicionais para o imóvel comercial ou residencial alugado. São vantagens para todos os lados!',
        shortcuts: [
          {
            label: 'Nova Proposta',
            color: 'seguros',
            icon: 'add',
            url: '/contratos/novo/essencial',
            hide: null,
            product: 'fianca',
          },
          {
            label: 'Parcelamento de aluguel',
            color: 'default',
            icon: 'holdingcard',
            url: '/sinistro/parcelamento-aluguel',
            hide: null,
            product: 'fianca',
          },
          {
            label: 'Assessoria de cobrança',
            color: 'default',
            icon: 'handmoney',
            url: '/sinistro/assessoria-de-cobranca',
            hide: (p) => p.type === 'BROKER',
            product: 'fianca',
          },
          {
            label: 'Sinistro',
            color: 'default',
            icon: 'residencealert',
            url: '/sinistro',
            hide: null,
            product: 'fianca',
          },
        ],
        side: [
          {
            Component: Anchors,
            props: {
              title: 'Navegue pelos tópicos',
              values: [
                {
                  label: 'Conheça os benefícios para você',
                  anchor: '#benefits_you',
                },
                {
                  label: 'Benefícios para seus clientes',
                  anchor: '#benefits_clients',
                },
                {
                  label: 'Compare os planos',
                  anchor: '#compare',
                },
                {
                  label: 'Coberturas e garantias de pagamento',
                  anchor: '#benefits_coverages',
                },
                {
                  label: 'Tire suas dúvidas',
                  anchor: '#faq',
                },
              ],
            },
          },
        ],
      },
    },
    {
      Component: BoxCards,
      props: {
        variant: 'secondary',
        title: 'Conheça os benefícios para você',
        anchor_id: 'benefits_you',
        values: [
          {
            cards: [
              {
                icon: 'handshake',
                title: 'Garantia de recebimento',
                subtitle: 'Receba as taxas administrativas.',
              },
              {
                icon: 'justicehammer',
                title: 'Equipe especializada sem custo',
                subtitle: 'Em eventuais ações de despejo.',
              },
              {
                icon: 'circledollarsign',
                title: 'Pagamento facilitado',
                subtitle: 'Até 60x sem juros na fatura da imobiliária.',
              },
              {
                icon: 'circlereload',
                title: 'Renovação simplificada',
                subtitle: 'Mais praticidade no seu dia a dia.',
              },
            ],
          },
        ],
      },
    },
    {
      Component: BoxCards,
      props: {
        variant: 'primary',
        title: 'Benefícios para seus clientes',
        anchor_id: 'benefits_clients',
        values: [
          {
            title: 'Inquilino',
            cards: [
              {
                icon: 'residence',
                title: 'Mais praticidade',
                subtitle: 'Inquilinos alugando mais rápido.',
              },
              {
                icon: 'holdingcard',
                title: 'Parcelamento do aluguel',
                subtitle: 'No momento do aperto.',
              },
              {
                icon: 'homeadd',
                title: 'Garantias adicionais',
                subtitle: 'Para encargos mensais, pintura e danos ao imóvel.',
              },
              {
                icon: 'circlereload',
                title: 'Renovação simplificada',
                subtitle: 'Mais uma facilidade.',
              },
              {
                icon: 'portoplus',
                title: 'Acesso ao PortoPlus',
                subtitle: 'Benefícios exclusivos para clientes Porto.',
              },
            ],
          },
          {
            title: 'Proprietário',
            cards: [
              {
                icon: 'circledollarsign',
                title: 'Garantia de recebimento',
                subtitle: 'No caso de inadimplência.',
              },
              {
                icon: 'shield',
                title: 'Tranquilidade',
                subtitle: 'O imóvel protegido em todos os momentos.',
              },
            ],
          },
        ],
      },
    },
    {
      Component: Comparison,
      props: {
        variant: 'primary',
        title: 'Compare os planos',
        anchor_id: 'compare',
        columns: 4,
        values: [
          {
            image: {
              src: '/images/produtos/fianca/produto-tradicional.png',
              alt: 'Foto de uma planta em um vaso',
            },
            title: 'Fiança Locatícia',
            benefits: [
              'Custo personalizado de acordo com o perfil do cliente;',
              'Indenização de até 30x o valor do aluguel + limite de coberturas adicionais;',
              'Descontos no PortoPlus;',
              'Parcelamento em até 10x sem juros;',
              '5 acionamentos gratuitos de serviços emergenciais para o imóvel.',
            ],
          },
          {
            image: {
              src: '/images/produtos/fianca/produto-essencial-12.png',
              alt: 'Foto de uma luminária',
            },
            title: 'Fiança Locatícia Essencial 12',
            benefits: [
              'Custo de 10% ao mês sobre o aluguel;',
              'Indenização de até 12x o valor do aluguel;',
              'Descontos no PortoPlus;',
              'Parcelamento sem juros no boleto ou cartão pelo tempo de contrato do aluguel;',
              'Sorteio anual de até 200 mil reais;',
            ],
          },
          {
            image: {
              src: '/images/produtos/fianca/produto-essencial-20.png',
              alt: 'Foto de um molho de chaves',
            },
            title: 'Fiança Locatícia Essencial 20',
            benefits: [
              'Custo de 12% ao mês sobre o aluguel;',
              'Indenização de até 20x o valor do aluguel;',
              'Descontos no PortoPlus;',
              'Parcelamento sem juros no boleto ou cartão pelo tempo de contrato do aluguel;',
              'Sorteio anual de até 200 mil reais;',
            ],
          },
          {
            image: {
              src: '/images/produtos/fianca/produto-essencial-30.png',
              alt: 'Foto de um sofé azul',
            },
            title: 'Fiança Locatícia Essencial 30',
            benefits: [
              'Custo de 14% ao mês sobre o aluguel;',
              'Indenização de até 30x o valor do aluguel;',
              'Descontos no PortoPlus;',
              'Parcelamento sem juros no boleto ou cartão pelo tempo de contrato do aluguel;',
              'Sorteio anual de até 200 mil reais;',
            ],
          },
        ],
      },
    },
    {
      Component: BoxCards,
      props: {
        variant: 'tertiary',
        title: 'Coberturas e garantias de pagamento',
        anchor_id: 'benefits_coverages',
        values: [
          {
            cards: [
              {
                icon: 'circledollarsign',
                legend: 'Básica',
                title: 'Aluguel e multa Moratória',
                subtitle: 'Até 30x o valor do aluguel.',
              },
              {
                icon: 'residence',
                legend: 'Adicional',
                title: 'Condomínio e IPTU',
                subtitle:
                  'Garantia do pagamento de 12, 20 ou 30x o valor do aluguel.',
              },
              {
                icon: 'residencealert',
                legend: 'Adicional',
                title: 'Danos ao imóvel',
                subtitle:
                  'Garantia de pagamento de danos causados ao imóvel: 2x ou 6x o valor do aluguel.',
              },
              {
                icon: 'paintroller',
                legend: 'Adicional',
                title: 'Pinturas internas e externas',
                subtitle: 'Até 3x o valor do aluguel.',
              },
              {
                icon: 'money',
                legend: 'Adicional',
                title: 'Multa por rescisão contratual',
                subtitle: 'Até 3x o valor do aluguel.',
              },
              {
                icon: 'idea',
                legend: 'Adicional',
                title: 'Água, luz e gás canalizado',
                subtitle:
                  'Pagamento de taxas de 6, 12, 20 ou 30x o valor declarado na apólice para cada item.',
              },
            ],
          },
        ],
      },
    },
    {
      Component: InfoAndActionSection,
      props: {
        variant: 'primary',
        title: 'Condições gerais',
        anchor_id: 'conditions',
        values: {
          info: 'Acesse os documentos que apresentam as condições de contratação.',
          action: {
            type: 'modal',
            label: 'Abrir condições gerais',
            modalTitle: 'Condições gerais',
            modalSubtitle:
              'Selecione produto para acessar o arquivo de Condições Gerais correspondente.',
            links: [
              {
                label: 'Fiança Locatícia',
                url: 'https://www.portoseguro.com.br/seguro-fianca',
              },
              {
                label: 'Fiança Locatícia Essencial',
                url: 'https://www.portoseguro.com.br/seguro-fianca/aluguel-essencial',
              },
            ],
          },
        },
      },
    },
    {
      Component: Faq,
      props: {
        variant: 'primary',
        title: 'Tire suas dúvidas',
        anchor_id: 'faq',
        values: [
          {
            question: 'O que é o Fiança Locatícia?',
            answer:
              'Para realizar a locação de um imóvel, sendo pessoa física ou jurídica, o locador pode exigir ao locatário algum tipo de garantia. O Fiança Locatícia é uma solução destinada para quem quer alugar um imóvel (inquilino) e/ou para quem possui um imóvel para alugar (proprietário). Esta modalidade, prevista em lei, substitui a necessidade de um fiador ou caução, no momento de alugar um imóvel comercial ou residencial.',
          },
          {
            question:
              'Como posso iniciar um orçamento e realizar o processo de contratação?',
            answer: `A contratação poderá ser feita clicando em, 'Nova Proposta' > “Fiança Locatícia ou Fiança Locatícia Essencial” ou no Painel lateral pelo menu clicando em “Fiança Locatícia ou Fiança Locatícia Esencial > Nova Proposta”. Antes de seguir com a contração do Fiança Locatícia, você deverá concordar com o nosso termo de aceite.`,
          },
          {
            question: 'Quais as vantagens em contratar o Fiança Locatícia?',
            answer:
              'Além de toda segurança oferecida pela Porto Bank no recebimento do aluguel, o proprietário conta com coberturas adicionais para IPTU, condomínio, água, luz, gás canalizado, multa por rescisão, pintura e danos ao imóvel.Tanto para o locador quanto para o locatário, o processo de contratação do fiança locatícia é mais simples e rápido do que encontrar e apresentar um fiador. Isso pode atrair mais interessados na locação.',
          },
          {
            question: 'O contrato do Fiança Locatícia pode ser dividido?',
            answer:
              'O Fiança Locatícia pode ser composto por outras pessoas que poderão complementar a renda ou apresentar renda em seu lugar (limitado a 10 pretendentes). Essa pessoa pode ser parente e residir no imóvel ou não, nesse caso, será considerado para efeito de comprometimento 15% da renda bruta do terceiro ou familiar.',
          },
          {
            question: 'Como funciona a vigência do Fiança Locatícia?',
            answer:
              'Ao fazer a contratação de um Fiança, é necessário informar a vigência, podendo ser um contrato de nova locação ou mesmo um de locação já em vigor.',
          },
        ],
      },
    },
    {
      Component: AlsoOffer,
      props: {
        variant: 'primary',
        title: 'Ofereça também',
        values: [
          {
            icon: 'fireflame',
            upperTitle: 'Garantia de aluguel',
            title: 'Seguro Incêndio',
            description:
              'Cobertura contra incêndio para imóveis alugados. Obrigatório por lei.',
            href: '/produtos/seguro-incendio',
          },
          {
            icon: 'ticket',
            upperTitle: 'Garantia de aluguel',
            title: 'Título de Capitalização',
            description:
              'Substituiu o fiador, não precisa de comprovação de renda e o inquilino resgata o valor no final do contrato.',
            href: '/produtos/titulo-de-capitalizacao',
          },
        ],
      },
    },
  ]

  return fiancaEssencialContent
}
