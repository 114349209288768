import { Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import {
  BoxCards,
  Header,
  InfoCard,
  Anchors,
  Faq,
} from '@/modules/static-page/components'
import BancoBariIcon from '../assets/banco_bari.jpg'

export const homeEquityContent = [
  {
    Component: Header,
    props: {
      title: 'Empréstimo com Garantia de Imóvel',
      subtitle:
        'Liberdade no uso do crédito e taxas baixas. Já o prazo e o valor liberado são altos.',
      description:
        'O Crédito com Garantia Imobiliária (CGI) é uma modalidade de crédito no qual há um imóvel como garantia do empréstimo, oferecendo as menores taxas do mercado. Por mais que o imóvel fique alienado ao banco até o pagamento total do empréstimo, ele continua sendo do cliente, que poderá utilizá-lo para qualquer finalidade, seja uso próprio ou aluguel.',
      shortcuts: [
        {
          label: 'Nova Proposta',
          color: 'seguros',
          icon: 'add',
          url: '/home-equity/estate-guarantee',
        },
      ],
      side: [
        {
          Component: InfoCard,
          props: {
            icon: 'idea',
            iconColor: 'porto-banking-primary',
            background: 'porto-primitive-off-white',
            title: 'Indique e ganhe',
            subtitle:
              'Se o cliente indicado contratar, você recebe {percent}% do valor do crédito.',
            legend:
              'R$ 300 mil é o valor médio de operações de CGI no Brasil (Fonte: Infomoney)',
          },
        },
        {
          Component: Anchors,
          props: {
            title: 'Navegue pelos tópicos',
            values: [
              {
                label: 'Benefícios',
                anchor: '#benefits',
              },
              {
                label: 'Parceria com o Banco Bari',
                anchor: '#bari_bank',
              },
              {
                label: 'Como funciona',
                anchor: '#contract_steps',
              },
              {
                label: 'Requisitos',
                anchor: '#requirements',
              },
              {
                label: 'Tire suas dúvidas',
                anchor: '#faq',
              },
            ],
          },
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'secondary',
      title: 'Benefícios',
      anchor_id: 'benefits',
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: '%',
              title: 'Taxas a partir de 1,15% ao mês + IPCA',
            },
            {
              icon: 'circledollarsign',
              title: 'Crédito de até 60% do valor do imóvel',
            },
            {
              icon: 'calendar',
              title: 'Prazo de até 240 meses',
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'primary',
      title: 'Parceria com o Banco Bari',
      description: 'Conheça um pouco do histórico de nosso parceiro.',
      anchor_id: 'bari_bank',
      image: {
        src: BancoBariIcon,
        alt: 'Logo do Banco Bari',
      },
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: 'handcoins2',
              title: 'Atua há 14 anos com crédito imobiliário',
              subtitle:
                'Fundado há 27 anos como Braço Financeiro do Grupo Barigui.',
            },
            {
              icon: 'money',
              title: 'R$ 1,3 bilhão de empréstimos imobiliários realizados',
              subtitle: 'Mais de 10 mil clientes atendidos.',
            },
            {
              icon: 'residence',
              title: '6º maior player e pioneiro na categoria',
              subtitle: 'Comercialização de Home Equity no Brasil.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'tertiary',
      title: 'Como funciona',
      description: 'Entenda a jornada completa, da indicação ao contrato.',
      anchor_id: 'contract_steps',
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: '1',
              title: 'Indicação do cliente',
              subtitle:
                'Imobiliária oferece o serviço e envia os dados do cliente.',
            },
            {
              icon: '2',
              title: 'Banco Bari entra em contato com cliente',
              subtitle:
                'Os documentos são solicitados e após validação é realizada a análise de crédito.',
            },
            {
              icon: '3',
              title: 'Crédito aprovado',
              subtitle:
                'Nessa etapa há a validação jurídica, emissão e assinatura do contrato.',
            },
            {
              icon: '4',
              title: 'Crédito em conta!',
              subtitle:
                'E, claro, a comissão é distribuída para a imobiliária.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'primary',
      title: 'Requisitos',
      anchor_id: 'requirements',
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: 'residence',
              title: 'Aceita imóveis residenciais, comerciais e terrenos',
            },
            {
              icon: 'like',
              title: 'Crédito para Pessoa Física e Pessoa Jurídica',
            },
            {
              icon: 'handcoins2',
              title: 'Valor mínimo em crédito de R$ 30 mil',
            },
            {
              icon: 'residencealert',
              title: 'Valor mínimo do imóvel de R$ 150 mil',
            },
          ],
        },
      ],
    },
  },
  {
    Component: Faq,
    props: {
      variant: 'primary',
      title: 'Tire suas dúvidas',
      anchor_id: 'faq',
      values: [
        {
          question: 'O que é Empréstimo com Garantia de Imóvel, da Porto Bank?',
          answer:
            'É uma modalidade de crédito no qual o cliente usa o imóvel dele como garantia da operação. Esse modelo também é conhecido como refinanciamento de imóvel ou home equity.<br />O capital adquirido por meio dessa transação pode ser usado como o cliente desejar.',
        },
        {
          question: 'O imóvel dado como garantia fica alienado?',
          answer:
            'Sim, o imóvel dado como garantia à instituição financeira (alienação fiduciária) fica alienado, ou seja, assegura o pagamento durante todo o período do financiamento.<br />No entanto, o imóvel continua sendo da pessoa, que pode usufruir como é de direito. Ainda assim, a propriedade fica condicionada ao pagamento total do empréstimo.',
        },
        {
          question: 'O Empréstimo com Garantia do Imóvel pode ser quitado?',
          answer:
            'Sim, você pode quitar o empréstimo a qualquer momento e o imóvel, neste caso, deixa de ser a garantia de pagamento.',
        },
        {
          question:
            'Posso fazer o Empréstimo com Garantia do Imóvel para propriedade com dívida?',
          answer:
            'O imóvel não precisa estar quitado, mas você precisa ter o número da matrícula do imóvel e ele precisa estar livre de ônus. Isso significa que o imóvel não pode ser objeto de nenhum tipo de penhora, usufruto ou demais averbações que não permitam a alienação do bem.',
        },
        {
          question: 'Quais são as etapas até a liberação do crédito?',
          answer: `Até a liberação do crédito do Empréstimo com Garantia do Imóvel Porto Bank, existem algumas etapas a seguir. Conheça:
            <br />
            1. simulação e solicitação de crédito: com o apoio do seu Corretor, você verá qual a melhor condição de crédito (valor, prazo, parcela) para fazer uma solicitação;<br />
            2. avaliação financeira: em seguida, seu perfil será avaliado por especialistas da companhia, a partir de uma análise de crédito rápida e segura. Se tudo estiver certo, você precisará enviar alguns documentos de comprovação;<br />
            3. avaliação do imóvel: como a garantia do empréstimo é seu imóvel, será feita uma vistoria para garantir que está tudo certo;<br />
            4. assinatura de contrato e registro em cartório: você terá acesso a todos os documentos para assinatura e registro em cartório (averbação da matrícula), configurando a alienação fiduciária;<br />
            5. liberação do crédito: após a confirmação do registro em cartório, será depositado o dinheiro na conta corrente indicada por você.<br />
            Não se preocupe, nós cuidamos para que todas as etapas aconteçam de forma rápida, transparente e segura.`,
        },
      ],
    },
  },
]
