import { RentItem } from '@/services/api/rentCollection'
import { parseCurrency } from '@brazilian-utils/brazilian-utils'
import { dataTable } from './data-table'

export function sheetToRentItemMapper(jsonSheetObject: object): RentItem {
  const jsonSheetObjectEntries = Object.entries(jsonSheetObject)

  const document = getColumnValue(jsonSheetObjectEntries, 'document')
  const tenant = getColumnValue(jsonSheetObjectEntries, 'tenant')
  const tenantSocialName = getColumnValue(jsonSheetObjectEntries, 'tenantSocialName')
  const phones = getColumnValue(jsonSheetObjectEntries, 'phones')
  const address = getColumnValue(jsonSheetObjectEntries, 'address')
  const number = getColumnValue(jsonSheetObjectEntries, 'number')
  const complement = getColumnValue(jsonSheetObjectEntries, 'complement')
  const city = getColumnValue(jsonSheetObjectEntries, 'city')
  const uf = getColumnValue(jsonSheetObjectEntries, 'uf')
  const zipcode = getColumnValue(jsonSheetObjectEntries, 'zipcode')
  const dueDate = getColumnValue(jsonSheetObjectEntries, 'dueDate')
  const debits = getColumnValue(jsonSheetObjectEntries, 'debits')

  return {
    address,
    city,
    complement,
    debits: typeof debits === 'string' ? parseCurrency(debits) : debits,
    document: String(document),
    dueDate,
    number,
    phones: String(phones).split(','),
    tenant,
    tenantSocialName,
    uf,
    zipcode: String(zipcode),
  }
}

const getColumnValue = (jsonSheetObjectEntries, dataTableKey) => {
  const [, value] = jsonSheetObjectEntries.find(
    ([key]) => key?.trim() === dataTable.find((item) => item.key === dataTableKey).header
  )

  return value
}
