import { useFormikContext } from 'formik'

import { Conditional, Content, Forms, Grid } from '@/components'
import { api } from '@/services'
import { removeSpecialChars, useFeatureEnable } from '@/tools'

import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { formatToPhone, isCPF, isCPFOrCNPJ } from 'brazilian-values'
import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { ImobiliariaBudget } from '..'
import { occupations } from './occupations'
import {
  BRAZILIAN_RESIDENT_OPTIONS,
  CIVIL_STATUS_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  INCOME_OPTIONS,
  NATIONALITY_OPTIONS,
  PEP_OPTIONS,
  RELATIONSHIP_OPTIONS,
} from './selectOptions'

const Customer = () => {
  const { values, setFieldValue } = useFormikContext<ImobiliariaBudget>()
  const featureEnable = useFeatureEnable()
  const isPF = useMemo(() => isCPF(values.customer_document), [values.customer_document])
  const isRenewal = useMemo(() => !!values.renewal_number, [values.renewal_number])

  const fetchCustomerMutation = useMutation(
    async (document: string) => {
      if (!isCPFOrCNPJ(document)) throw new Error('Invalid document')

      const data = await api.customers.getByDocument(document).catch(() => undefined)

      if (isCPF(document)) {
        setFieldValue('customer_name', data?.name)
        setFieldValue('customer_phone', formatToPhone(data?.phone || ''))
        setFieldValue('customer_birthdate', data?.birthdate)
      } else {
        setFieldValue('company_phone', formatToPhone(data?.phone || ''))
        setFieldValue('company_name', data?.companyName)
        setFieldValue('foundation_date', data?.foundation_date)
      }

      return data
    },
    {
      onMutate() {
        setFieldValue('customer_name', '')
        setFieldValue('customer_birthdate', '')
        setFieldValue('customer_email', '')
        setFieldValue('customer_phone', '')
        setFieldValue('civil_status', '')
        setFieldValue('customer_income', '')
        setFieldValue('customer_occupation', '')
        setFieldValue('customer_pep', '')
      },
    },
  )

  const showCustomersInfo = useMemo(
    () =>
      (fetchCustomerMutation.isSuccess ||
        isRenewal ||
        values.customer_document ||
        values.customer_name) &&
      !fetchCustomerMutation.isLoading &&
      isCPFOrCNPJ(values.customer_document),
    [
      fetchCustomerMutation.isLoading,
      fetchCustomerMutation.isSuccess,
      isRenewal,
      values.customer_document,
      values.customer_name,
    ],
  )

  return (
    <Forms.Card
      step={isRenewal ? '2' : '3'}
      title="Cliente"
      helper={!isRenewal ? 'Preencha as <b>informações básicas</b> do cliente.' : null}
      active
    >
      <Grid gap="16px">
        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
          <Forms.InputGroup
            name="customer_document"
            label="CPF ou CNPJ"
            showRequired
            placeholder="Digite o CPF ou CNPJ"
            mask="document"
            readOnly={fetchCustomerMutation.isLoading || isRenewal}
            onBlur={() => fetchCustomerMutation.mutateAsync(values.customer_document)}
            data-gtm-form="input"
            data-gtm-name="cpf-cnpj"
            data-gtm-subname="cliente"
            data-test-id="cpf-cnpj"
          />
        </Grid>

        {fetchCustomerMutation.isLoading && (
          <Content.Loader
            alignment="left"
            message="Aguarde, estamos buscando os dados do cliente."
          />
        )}

        {showCustomersInfo && (
          <>
            {isPF ? (
              <>
                <Grid columns={['1fr', '1fr', '2fr 1fr']}>
                  <Forms.InputGroup
                    name="customer_name"
                    label="Nome completo"
                    showRequired
                    tip="Para imóveis de orgãos públicos, o corretor deve entrar em contato com o departamento de Licitações."
                    placeholder="Digite o nome completo"
                    readOnly={isRenewal}
                    data-gtm-form="input"
                    data-gtm-name="nome-completo-razao-social"
                    data-gtm-subname="cliente"
                    data-test-id="customer-name"
                  />
                </Grid>

                {featureEnable.socialNameProducts.socialNameImobiliaria && (
                  <AnimateVerticalShow isOpen={isCPF(values.customer_document)}>
                    <SocialNameField
                      textFieldName="customer_social_name"
                      toggleName="customer_social_name_toggle"
                    />
                  </AnimateVerticalShow>
                )}

                <Grid gap="16px" columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
                  <Forms.InputGroup
                    name="customer_birthdate"
                    label="Data de nascimento"
                    showRequired
                    placeholder="dd/mm/aaaa"
                    mask="date"
                    data-test-id="birthdate"
                  />
                  <Forms.SelectGroup
                    name="civil_status"
                    resetValueOnUnmount={false}
                    label="Estado civil"
                    showRequired
                    options={[
                      {
                        label: 'Selecione',
                        value: '',
                      },
                      ...CIVIL_STATUS_OPTIONS,
                    ]}
                    data-test-id="civil-status"
                  />
                </Grid>

                <Grid gap="16px" columns={['1fr', '1fr', '1fr 2fr 8px']}>
                  <Forms.InputGroup
                    name="customer_phone"
                    label="Telefone ou Celular"
                    showRequired
                    placeholder="Digite o telefone ou celular"
                    mask="phone"
                    data-gtm-form="input"
                    data-gtm-name="telefone-ou-celular"
                    data-gtm-subname="cliente"
                    data-test-id="phone"
                  />

                  <Forms.InputGroup
                    name="customer_email"
                    label="E-mail"
                    showRequired
                    placeholder="Digite o e-mail"
                    data-gtm-form="input"
                    data-gtm-name="email"
                    data-gtm-subname="cliente"
                    data-test-id="email"
                  />
                </Grid>

                <Grid gap="16px" columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
                  <Forms.Autocomplete
                    name="customer_occupation"
                    label="Profissão"
                    emptyMessage="Nenhuma atividade encontrada"
                    showRequired
                    onSearch={async term =>
                      Object.entries(occupations)
                        .filter(([, value]) =>
                          removeSpecialChars(value)
                            .toLowerCase()
                            .includes(removeSpecialChars(term).toLocaleLowerCase()),
                        )
                        .map(([key, value]) => ({
                          label: value,
                          value: key,
                        }))
                    }
                  />

                  <Forms.SelectGroup
                    name="customer_income"
                    label="Faixa de renda"
                    resetValueOnUnmount={false}
                    showRequired
                    options={[
                      {
                        label: 'Selecione',
                        value: '',
                      },
                      ...INCOME_OPTIONS,
                    ]}
                    data-test-id="income"
                  />
                </Grid>

                <Grid columns={['1fr', '1fr', '1fr 1fr']}>
                  <Forms.SelectGroup
                    name="customer_pep"
                    label="Pessoa politicamente exposta (PEP)"
                    resetValueOnUnmount={false}
                    showRequired
                    options={[
                      {
                        label: 'Selecione',
                        value: '',
                      },
                      ...PEP_OPTIONS,
                    ]}
                    data-test-id="pep"
                  />
                </Grid>
                <Conditional when={values.customer_pep === '1'}>
                  <Grid gap="16px" columns={['1fr', '1fr', '1fr 1fr 1fr']}>
                    <Forms.SelectGroup
                      name="pep_document_type"
                      label="Documento de identificação"
                      resetValueOnUnmount={false}
                      showRequired
                      options={[
                        {
                          label: 'Selecione',
                          value: '',
                        },
                        ...DOCUMENT_TYPE_OPTIONS,
                      ]}
                      data-test-id="pep-doc-type"
                    />
                  </Grid>
                  <Conditional when={!!values.pep_document_type}>
                    <Grid gap="16px" columns={['1fr', '1fr', '1fr 1fr 1fr']}>
                      <Forms.InputGroup
                        name="pep_document"
                        label="Numero do documento"
                        showRequired
                        placeholder="Digite"
                        maxLength={14}
                        data-test-id="pep-document"
                      />
                      <Forms.InputGroup
                        name="pep_document_dispatcher"
                        label="Órgão Expedidor"
                        showRequired
                        placeholder="Digite"
                        maxLength={14}
                        data-test-id="pep-doc-dispatcher"
                      />
                      <Conditional when={['1', '40'].includes(values.pep_document_type)}>
                        <Forms.InputGroup
                          name="pep_document_expedition_date"
                          label="Data de expedição (dd/mm/aaaa)"
                          showRequired
                          placeholder="dd/mm/aaaa"
                          mask="date"
                          data-test-id="pep-doc-expedition"
                        />
                      </Conditional>
                      <Conditional when={!['1', '40'].includes(values.pep_document_type)}>
                        <Forms.InputGroup
                          name="pep_document_expiration_date"
                          label="Data de validade (dd/mm/aaaa)"
                          showRequired
                          placeholder="dd/mm/aaaa"
                          mask="date"
                          data-test-id="pep-doc-expiration"
                        />
                      </Conditional>
                    </Grid>
                    <Grid gap="16px" columns={['1fr', '1fr', '1fr 1fr 1fr']}>
                      <Conditional when={!['1', '5'].includes(values.pep_document_type)}>
                        <Forms.SelectGroup
                          name="pep_nationality"
                          label="Nacionalidade"
                          resetValueOnUnmount={false}
                          showRequired
                          options={[
                            {
                              label: 'Selecione',
                              value: '',
                            },
                            ...NATIONALITY_OPTIONS,
                          ]}
                          data-test-id="pep-nationality"
                        />
                      </Conditional>
                      <Forms.SelectGroup
                        name="pep_brazilian_resident"
                        label="Reside no Brasil?"
                        resetValueOnUnmount={false}
                        showRequired
                        options={[
                          {
                            label: 'Selecione',
                            value: '',
                          },
                          ...BRAZILIAN_RESIDENT_OPTIONS,
                        ]}
                        data-test-id="pep-resident"
                      />
                    </Grid>
                  </Conditional>
                </Conditional>
                <Conditional when={values.customer_pep === '3'}>
                  <Grid gap="16px" columns={['1fr', '1fr', '1fr 1fr 1fr']}>
                    <Forms.InputGroup
                      name="pep_cpf"
                      label="CPF da PEP"
                      showRequired
                      placeholder="Digite o CPF"
                      mask="cpf"
                      data-test-id="pep-cpf"
                    />
                    <Forms.InputGroup
                      name="pep_name"
                      label="Nome completo da PEP"
                      showRequired
                      placeholder="Digite"
                      data-test-id="pep-name"
                    />
                    <Forms.SelectGroup
                      name="pep_relationship_type"
                      resetValueOnUnmount={false}
                      label="Grau de relacionamento"
                      showRequired
                      options={[
                        {
                          label: 'Selecione',
                          value: '',
                        },
                        ...RELATIONSHIP_OPTIONS,
                      ]}
                      data-test-id="pep-type"
                    />
                  </Grid>
                </Conditional>
              </>
            ) : (
              <>
                <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}>
                  <Forms.InputGroup
                    name="company_name"
                    label="Razão social"
                    showRequired
                    placeholder="Digite"
                    data-test-id="company-name"
                  />
                </Grid>
                <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
                  <Forms.InputGroup
                    name="foundation_date"
                    label="Data de constituição"
                    showRequired
                    placeholder="dd/mm/aaaa"
                    mask="date"
                    data-test-id="foundation-date"
                  />
                </Grid>
                <Grid gap="16px" columns={['1fr', '1fr', '1fr 2fr 8px']}>
                  <Forms.InputGroup
                    name="company_phone"
                    label="Telefone ou Celular"
                    showRequired
                    placeholder="Digite o telefone ou celular"
                    mask="phone"
                    data-test-id="company-phone"
                  />
                  <Forms.InputGroup
                    name="company_email"
                    label="E-mail"
                    showRequired
                    placeholder="Digite o e-mail"
                    data-gtm-form="input"
                    data-gtm-name="email"
                    data-gtm-subname="cliente"
                    data-test-id="company-email"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Forms.Card>
  )
}

export default Customer
