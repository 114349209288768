import { Button as ButtonComponent } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import styled, { css } from 'styled-components'

export const Button = styled(ButtonComponent)`
  ${({ kind }) =>
    kind === 'ghost' &&
    css`
      border-color: #c8c8c8 !important;
      border-radius: 8px !important;
      justify-content: space-between !important;

      > span {
        color: #000;
        -webkit-text-fill-color: #000;
      }
    `}
`
