import api from '../index'

export async function refreshToken(refresh_token: string) {
  try {
    const response = await api.instance.v2.post<{
      access_token: string
      refresh_token: string
    }>('/auth/token/refresh', {
      refresh_token: refresh_token,
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function signInWithEmailAndPassword(
  email: string,
  password: string
) {
  try {
    const response = await api.instance.v1.post('/auth/login', {
      email,
      password,
    })

    const { access_token, refresh_token } = response.data

    return {
      access: access_token,
      refresh: refresh_token,
    }
  } catch (error) {
    throw error
  }
}

export async function currentUser(email: string) {
  try {
    const response = await api.instance.v1.get(`/users`)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateProfile(
  email: string,
  payload: {
    avatar?: any
    cpf?: string
    email?: string
    name?: string
    phone?: string
    password?: string
    passwordConfirm?: string
  },
  hash?: string
) {
  const formData = new FormData()

  for (const [field, value] of Object.entries(payload)) {
    formData.append(field, value)
  }

  try {
    const response = await api.instance.v2.put(`/users`, { ...payload, currentEmail: email }, {
      headers: {
        'x-oob-code': hash || undefined,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function resetPassword(email: string) {
  try {
    const response = await api.instance.v2.delete(`/users/password`, {
      data: {
        email
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function updatePassword(
  email: string,
  passwords: {
    new: string
    current?: string
  },
  hash?: string
) {
  try {
    const response = await api.instance.v2.patch(
      `/users/password`,
      {
        email,
        password: passwords.new,
        currentPassword: passwords?.current || undefined,
      },
      {
        params: {
          hash: hash || undefined,
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export function verifyToken(
  hash: string
) {
  return new Promise((res,rej) => {
    api.instance.v2.get(
      `/auth/token/verify`,
      { params: { hash: hash }}
    ).then(({ data }) => {
      res(data)
    }).catch(e => {
      rej(e.response?.data)
    })
  })
}

export async function confirmEmailVerificationCode(
  email: string,
  code: string
) {
  try {
    const response = await api.instance.v1.put(`/users/email/confirm`, {
      email,
      verificationCode: code,
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function importOrganizations(email: string) {
  try {
    const response = await api.instance.v2.post(`/users/import`, {
      email
    })

    return response.data
  } catch (error) {
    throw error
  }
}
