import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import ExternalLink from '@/components/ExternalLink'
import { useCurrentUser } from '@/modules'
import { useRegistration } from '@/tools'
import { AxiosResponse } from 'axios'
import { useMemo } from 'react'

const PartnerRegistrationError = () => {
  const currentUser = useCurrentUser()
  const registrationQuery = useRegistration(currentUser.store?.email)

  const message = useMemo(() => {
    const axiosError = registrationQuery?.error as AxiosResponse

    if (!axiosError || axiosError?.status >= 500)
      return 'Ocorreu um erro ao tentar realizar seu registro. Tente novamente mais tarde...'

    return axiosError?.data?.message
  }, [registrationQuery])

  return (
    <Flex
      sx={{
        position: 'fixed',
        flexDirection: 'column',
        gap: '2rem',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="porto-title-5-semibold" content={message} />
      <ExternalLink
        label="Precisa de ajuda?"
        icon="circlequestion"
        linkProps={{
          href: 'http://ajuda.solucoesparalocacao.com.br/',
          target: '_blank',
        }}
      />
    </Flex>
  )
}

export default PartnerRegistrationError
