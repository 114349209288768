import dayjs from 'dayjs'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { Conditional, Content, Forms, Grid, Info, toast } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import UploadModal from '@/screens/BatchBudget/components/UploadModal'
import { useBudgetsBatch, useCountBudgets } from '@/screens/BatchBudget/hooks'
import { plansParse } from '@/screens/BatchBudget/utils'
import { api } from '@/services'
import { formatMoneyBRL, limitCaracter } from '@/tools'

import FilterBudgets from '../FilterBudgets'
import InputPayment from '../Payment'
import { ValidationTooltip } from './validateStatus'

import {
  Action,
  ActionsWrapper,
  BackButton,
  Container,
  ContainerWrapper,
  ContentHeader,
  ContractsLabels,
  ErrorStyle,
  FailureLabel,
  FirstChild,
  FirstValue,
  FormCheck,
  FormFirstCheck,
  FormWrapper,
  Label,
  Payment,
  Right,
  TitleLabel,
  ValueTotal,
} from './styles'

import { Products } from '@/screens/Contracts/Products/shared/entities'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import CardNotice from '../CardNotice'
import { labelDescription } from '../CardNotice/utils'

export type BudgetPayment = {
  contracts: string[]
  batch: string
  installment: string
  method: string
}

type Batch = {
  id: string
  state: string
  status: string
  organizationType: string
  product: Products
  filename: string
  // TODO: Melhorar tipagem, passar tipagem de imobiliária
  partner: Record<string, any>
  broker: string

  budgets: unknown[]
  susep: string

  period_start: string | Date

  created_at: string | Date
  updated_at: string | Date
}

const DetailsList = () => {
  const params = useParams<{ id: string }>()

  const { budgets, filters, isFetching, syncBudgets } = useBudgetsBatch(params.id)

  const [loadingRecalculated, setLoadingRecalculated] = useState<boolean>(false)
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState(false)
  const [valuePay, setValuePay] = useState(0)
  const [alert, setAlert] = useState(false)

  const history = useHistory()
  const { state: batch } = useLocation<Batch>()

  const onSubmit = async values => {
    if (values.undefined) delete values.undefined

    try {
      setLoadingOrder(true)

      await api.batchs.order(values)

      UploadModal({
        icon: SvgIcons['uploadCheck'],
        title: 'Sucesso!',
        description:
          'A contratação foi realizada com sucesso! Clique abaixo para consultar o status dos seus orçamentos.',
        labelSubmit: 'Consultar status do orçamento',
        onSubmit: () => history.push(`/orcamento/consulta`),
      })
    } catch (error) {
      toast('Ocorreu algum erro ao contratos os orçamentos.', {
        type: 'error',
      })
    } finally {
      setLoadingOrder(false)
    }
  }

  const initialValues = {
    contracts: [],
    batch: params.id,
    installment: '',
    method: '',
  }

  const form = useFormik<BudgetPayment>({
    initialValues,
    onSubmit,
  })

  const resubmitContracts = async () => {
    try {
      setLoadingRecalculated(true)
      const contractsId = budgets
        ?.filter(budget => budget.external_status === 'failure')
        .map(item => item.id)

      await api.batchs.calculate(params.id, contractsId)

      UploadModal({
        icon: SvgIcons['uploadCheck'],
        title: 'Sucesso!',
        description: 'Os dados foram reenviados com sucesso!',
      })
    } catch (error) {
      toast('Ocorreu algum erro ao recalcular os orçamentos.', {
        type: 'error',
      })
    } finally {
      setLoadingRecalculated(false)
    }
  }

  const handleFilter = async () => {
    const newFilters = await FilterBudgets.openModal(filters.get())

    filters.apply(newFilters)
  }

  const handleExport = async () => {
    const { data } = await api.instance.v2.get('/contracts/extract', {
      responseType: 'blob',
      params: {
        batch: params.id,
      },
    })

    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(new Blob([data]))
    link.download = `lote-${params.id}.xlsx`
    link.click()
  }

  const hasFilters =
    !!filters.get() &&
    Object.values(filters.get())?.some(filter => {
      if (Array.isArray(filter) && !!filter.length) return true
      if (Boolean(filter) && !Array.isArray(filter)) return true
      return false
    })

  const selectedCalculateds = () => {
    setIsChecked(!isChecked)
    if (form.values.contracts.length > 0 && isChecked) {
      form.setFieldValue('contracts', [])
    } else {
      const budgetsId = budgets
        .filter(budget => budget.external_status === 'calculated')
        .map(item => item.id)

      form.setFieldValue('contracts', budgetsId)
    }
  }

  const validateCheck = (status: string, id: string) => {
    const validate = form.values.contracts.includes(id)
    if (status === 'calculated' && validate) {
      return true
    }

    return false
  }

  useEffect(() => {
    const budgetsIds = form.values.contracts
    let valuePay = 0

    budgets
      .filter(budget => budgetsIds.includes(budget.id))
      .map(item => {
        return (valuePay = valuePay + item.budgets[0].quotation.prize.total)
      })

    setValuePay(valuePay)
  }, [form.values.contracts, budgets])

  useEffect(() => {
    if (budgets.length === 0 && !isFetching) {
      filters.apply({})

      setAlert(true)
    }
  }, [budgets, filters, isFetching])

  return (
    <Container>
      <Content.Heading
        title="Consultar status do orçamento em lote"
        subtitle="Selecione aqui o orçamento calculado e escolha a forma de pagamento."
      />

      <Conditional when={isFetching}>
        <Content.Loader message="Aguarde, buscando contratos." />
      </Conditional>
      <Conditional when={alert && !isFetching}>
        <CardNotice icon="alertIcon" description={labelDescription} />
      </Conditional>

      <Conditional when={loadingRecalculated}>
        <Content.Loader message="Aguarde, reenviando orçamentos." />
      </Conditional>

      <Conditional when={loadingOrder}>
        <Content.Loader message="Aguarde, contratação dos orçamentos." />
      </Conditional>

      <Conditional when={!isFetching && !loadingRecalculated && !loadingOrder}>
        <ContainerWrapper>
          <FormikProvider value={form}>
            <ContentHeader>
              <ActionsWrapper>
                <Action kind={!hasFilters && 'ghost'} icon="filter" onClick={handleFilter}>
                  {hasFilters ? 'Editar filtros' : 'Filtros'}
                </Action>
                <Action kind="ghost" icon="file" onClick={handleExport}>
                  Exportar
                </Action>
              </ActionsWrapper>

              <Action
                iconLeft
                isFeatherIcons={false}
                kind="ghost"
                icon="reload"
                onClick={syncBudgets}
                isDisabled={isFetching}
              >
                Atualizar status
              </Action>
            </ContentHeader>

            <form onSubmit={form.handleSubmit}>
              <FailureLabel>
                Ocorreu{' '}
                <ErrorStyle>
                  {' '}
                  falha na integração em {useCountBudgets(budgets, 'failure')} itens{' '}
                </ErrorStyle>
                , clique em <span onClick={() => resubmitContracts()}>reenviar</span> para
                calcularmos novamente.
              </FailureLabel>

              <FormFirstCheck checkGlobal={true}>
                <Label>
                  <Forms.Choice type="checkbox" onChange={() => selectedCalculateds()} />
                  Selecionar{' '}
                  <span>{useCountBudgets(budgets, 'calculated')} orçamentos calculados.</span>
                </Label>
              </FormFirstCheck>

              <FormWrapper>
                {budgets.map((budget, key) => {
                  const errorLabel = budget.metadata.error?.cause?.exception?.errors?.message

                  return (
                    <FormCheck
                      failure={budget.external_status === 'done' ? false : true}
                      key={key}
                      onClick={() => {
                        budget?.external_status === 'done' &&
                          history.push(`/contratos/${budget.id}`, {
                            state: budget,
                          })
                      }}
                    >
                      <Label>
                        <Forms.Choice
                          key={key}
                          type="checkbox"
                          name="contracts"
                          value={budget.id}
                          checked={validateCheck(budget.external_status, budget.id)}
                          disabled={budget.external_status !== 'calculated'}
                        />
                      </Label>
                      <ContractsLabels>
                        <TitleLabel>Proponente</TitleLabel>
                        <FirstChild>
                          <Grid columns="repeat(2, max-content)" gap="8px">
                            <Typography variant="">
                              {limitCaracter(
                                budget?.payload.customer.socialName ||
                                  budget?.payload.customer.name,
                                20,
                                '...',
                              )}
                            </Typography>
                            {!!budget?.payload.customer.socialName && (
                              <Info content="Este é o nome social do cliente e deve ser utilizado em todas as comunicações. O nome de registro, abaixo, é apenas para consulta." />
                            )}
                          </Grid>

                          {!!budget?.payload.customer.socialName && (
                            <Typography
                              variant="porto-text-label-regular"
                              color="system-text-tertiary"
                            >
                              {budget?.payload.customer.name}
                            </Typography>
                          )}
                        </FirstChild>

                        {errorLabel && (
                          <Typography
                            as="span"
                            variant="porto-text-label-bold"
                            sx={{
                              position: 'absolute',
                              top: 'calc(100% - 4px)',
                              textWrap: 'nowrap',
                            }}
                          >
                            Retorno do produto:{' '}
                            <Typography
                              as="span"
                              color="porto-primitive-red-100"
                              variant="porto-text-label-bold"
                            >
                              {Array.isArray(errorLabel) ? errorLabel.join(', ') : errorLabel}
                            </Typography>
                          </Typography>
                        )}
                      </ContractsLabels>
                      <ContractsLabels>
                        <TitleLabel>Tipo de plano</TitleLabel>
                        <FirstValue>{plansParse[budget?.payload?.contract.plan]}</FirstValue>
                      </ContractsLabels>
                      <ContractsLabels>
                        <TitleLabel>Status atualizado em</TitleLabel>
                        <FirstValue>
                          {!budget.payload.synced_at
                            ? '--'
                            : dayjs(budget.payload.synced_at).format('DD/MM/YYYY HH:mm:ss')}
                        </FirstValue>
                      </ContractsLabels>
                      <Conditional when={budget.status !== 'FAILURE'}>
                        <Conditional when={budget?.budgets[0]?.id}>
                          <ContractsLabels>
                            <TitleLabel>N° do orçamento</TitleLabel>
                            <FirstValue>{budget?.budgets[0]?.id}</FirstValue>
                          </ContractsLabels>
                          <Conditional when={budget.external_status === 'done'}>
                            <ContractsLabels>
                              <TitleLabel>N° da proposta</TitleLabel>
                              <FirstValue>{budget?.policy?.proposal}</FirstValue>
                            </ContractsLabels>
                            <Conditional when={budget?.policy?.id}>
                              <ContractsLabels>
                                <TitleLabel>N° da apólice</TitleLabel>
                                <FirstValue>
                                  {limitCaracter(budget?.policy?.id, 10, '...')}
                                </FirstValue>
                              </ContractsLabels>
                            </Conditional>
                          </Conditional>
                        </Conditional>

                        <Conditional when={budget.external_status === 'calculated'}>
                          <ContractsLabels>
                            <TitleLabel>Valor a pagar</TitleLabel>
                            <FirstValue>
                              {formatMoneyBRL(
                                budget?.budgets?.map(payable => {
                                  return payable?.quotation?.prize?.total
                                }),
                              )}
                            </FirstValue>
                          </ContractsLabels>
                        </Conditional>
                      </Conditional>
                      <Right>
                        <ValidationTooltip contract={budget} />
                      </Right>
                    </FormCheck>
                  )
                })}
              </FormWrapper>

              <Payment>
                <ValueTotal>Valor Total {formatMoneyBRL(valuePay)}</ValueTotal>
                <Right>
                  <InputPayment
                    labelSelect="Selecione a forma de pagamento"
                    budgets={budgets?.filter(budget => budget.external_status === 'calculated')}
                    isDisabled={
                      new Date(batch.period_start).getTime() < new Date().setHours(0, 0, 0, 0) ||
                      form.values.contracts.length === 0
                    }
                  />

                  <Action
                    type="submit"
                    isDisabled={
                      !form.values.method ||
                      new Date(batch.period_start).getTime() < new Date().setHours(0, 0, 0, 0) ||
                      form.values.installment === '1' ||
                      form.values.contracts.length === 0
                    }
                  >
                    Contratar
                  </Action>
                </Right>
              </Payment>
            </form>
          </FormikProvider>
        </ContainerWrapper>
      </Conditional>
      <BackButton>
        <Action kind="ghost" icon="arrow-left" onClick={() => history.push(`/orcamento/consulta`)}>
          Voltar
        </Action>
      </BackButton>
    </Container>
  )
}

export default DetailsList
