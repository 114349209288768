import { Actions, Forms, Icons } from '@/components'
import { FloatingCombo } from '@/components/FloatingCombo'
import { IFloatingComboOption } from '@/components/FloatingCombo/interfaces'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { api } from '@/services'
import { PaginationProvider } from '@/tools'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Icon as PortoIcon,
  Tabs,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { ITabsProps } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto/Tabs/interfaces'
import Icon from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system/Icon'
import { saveAs } from 'file-saver'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { LastUpdate, Pagination, Table, UpdateContracts } from '../components'
import { TABLE_COLUMNS, getContractKey, getContractOptions } from '../data/table.data'
import { createdAtFilterCustom, createdAtFilterOptions, statusFilterOptions } from './FilterOptions'
import { FormValues, dateEnd, dateStart, initialValues, schema } from './form'
import * as S from './styles'
import { FilterOption } from './types'

const TABS: ITabsProps['tabs'] & Record<string, unknown>[] = [
  {
    icon: 'fireflame',
    label: 'Seguro Incêndio',
    tooltip: {
      description: 'Seguro Incêndio',
    },
    filterValue: Products.IMOBILIARIA,
  },
  {
    icon: 'receiptcheck',
    label: 'Fiança Locatícia (Novo)',
    tooltip: {
      description: 'Fiança Locatícia',
    },
    filterValue: Products.ESSENCIAL,
  },
  {
    icon: 'residence',
    label: 'Fiança Locatícia (Legado)',
    tooltip: {
      description:
        'Contratos do Fiança Locatícia gerados pelo formulário que foi descontinuado em Maio de 2024.',
    },
    filterValue: Products.ALUGUEL,
  },
  {
    icon: 'circledollarsign',
    label: 'Titulo de Capitalização',
    tooltip: {
      description: 'Título de Capitalização',
    },
    filterValue: Products.CAPITALIZACAO,
  },
]

const initialFilterOption: FilterOption = {
  start: dateStart,
  end: dateEnd,
  search: '',
  status: [],
}

export default function BudgetList() {
  const [filters, setFilters] = useState(initialFilterOption)
  const [lastSynced, setLastSynced] = useState(new Date())
  const [showCustomCreatedAtFilter, setShowCustomCreatedAtFilter] = useState(false)
  const [productStatusFilterOptions, setProductStatusFilterOptions] =
    useState<IFloatingComboOption[]>(statusFilterOptions)
  const [showFilters, setShowFilters] = useState(false)
  const [showNewProposalMenu, setShowNewProposalMenu] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const form = useFormik<FormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit: data => {
      setFilters(values => ({
        ...values,
        start: data.dateStart,
        end: data.dateEnd,

        search: data.search || undefined,
      }))
    },
  })

  const { setFieldValue } = form

  useEffect(() => {
    const currentProduct = TABS[currentTab].filterValue
    const statusOptions = statusFilterOptions.filter(statusOption =>
      statusOption.products.includes(currentProduct),
    )

    setProductStatusFilterOptions(statusOptions)
    setFilters(initialFilterOption)
    setFieldValue('search', '')
  }, [currentTab, setFieldValue])

  const exportContractsMutation = useMutation(async () => {
    const { data: buffer } = await api.instance.v2.get(`/contracts`, {
      responseType: 'arraybuffer',
      params: {
        ...filters,
        product: TABS[currentTab].filterValue,
      },
      headers: {
        Accept: 'text/csv',
      },
    })

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    })
    saveAs(
      blob,
      /* 
        TODO: Passar um nome no qual dê para se identificar:
          - data inicial
          - data final
      */

      `contracts.xls`,
    )
  })

  const handleLastSynced = (param: Date) => {
    setLastSynced(param)
  }

  const restartFilters = () => {
    setFilters(initialFilterOption)
  }

  const createdAtFilterCustomHadErrors = (): boolean => {
    return Boolean(
      showCustomCreatedAtFilter && (form.errors['dateStart'] || form.errors['dateEnd']),
    )
  }

  const applyCreatedAtFilter = (selecteds: IFloatingComboOption[]) => {
    const dateFilters = {
      startDate: new Date(),
      endDate: new Date(),
    }

    if (!selecteds[0]) {
      dateFilters.startDate = dateStart
      dateFilters.endDate = dateEnd
    } else if (showCustomCreatedAtFilter) {
      if (createdAtFilterCustomHadErrors()) {
        return
      }

      dateFilters.startDate = form.values.dateStart
      dateFilters.endDate = form.values.dateEnd
    } else {
      const daysToSubtract: number = selecteds[0].value as number

      dateFilters.startDate.setDate(dateFilters.startDate.getDate() - daysToSubtract)
    }

    setFilters(filters => ({
      ...filters,
      start: dateFilters.startDate,
      end: dateFilters.endDate,
    }))
  }

  const handleCreatedAtFilterChange = (selecteds: IFloatingComboOption[]) => {
    const showCustom = selecteds[0]?.value === createdAtFilterCustom

    setShowCustomCreatedAtFilter(showCustom)
  }

  const processCreatedAtFilterLabel = (): string | undefined => {
    if (showCustomCreatedAtFilter && !createdAtFilterCustomHadErrors()) {
      const dateStart = form.values.dateStart
      const dateEnd = form.values.dateEnd

      return `${dateStart.toLocaleDateString('pt-BR')} a 
        ${dateEnd.toLocaleDateString('pt-BR')}`
    }
  }

  const applyStatusFilter = (selecteds: IFloatingComboOption[]) => {
    const status = selecteds.map(selected => selected.value.toString())

    setFilters(filters => ({
      ...filters,
      processed_status: status,
    }))
  }

  const handleChangeTab = (index: number) => {
    setCurrentTab(index)
  }

  const maxDate = useMemo(() => new Date(), [])

  return (
    <PaginationProvider product={TABS[currentTab].filterValue} totalPages={10} filters={filters}>
      <Flex
        sx={{
          flexDirection: 'column',
          padding: '16px, 24px, 12px, 24px',
          gap: '16px',
          marginBottom: '30px',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Typography
            content="Gestão de Carteira"
            variant="porto-title-4-bold"
            data-test-id="title"
          />
          <Flex sx={{ gap: '0.5rem' }}>
            <LastUpdate {...{ lastSynced }} />
            <UpdateContracts {...{ handleLastSynced }} />
            <Actions.Button
              size="small"
              kind="simple"
              onClick={() => exportContractsMutation.mutateAsync()}
              isDisabled={exportContractsMutation.isLoading}
              data-test-id="export"
            >
              {exportContractsMutation.isLoading ? (
                <Icons.Loader size="24px" />
              ) : (
                <Icons.FeatherIcons name="download" />
              )}
            </Actions.Button>

            <S.NewProposalContainer>
              <Actions.Button
                iconLeft={true}
                onClick={() => setShowNewProposalMenu(prevShow => !prevShow)}
                data-test-id="new-proposal"
              >
                Nova proposta
                <Icon name="chevron_down" />
              </Actions.Button>

              {showNewProposalMenu && (
                <S.NewProposalOptionsContainer>
                  <Link to="/contratos/novo/essencial">
                    <PortoIcon name="receiptcheck" /> Fiança locatícia
                  </Link>
                  <Link to="/contratos/novo/capitalizacao">
                    <PortoIcon name="list2" /> Título de capitalização
                  </Link>
                  <Link to="/contratos/novo/imobiliaria">
                    <PortoIcon name="fireflame" /> Seguro incêndio
                  </Link>
                </S.NewProposalOptionsContainer>
              )}
            </S.NewProposalContainer>
          </Flex>
        </Flex>
      </Flex>

      <Tabs tabs={TABS} initialActive={currentTab} onChange={handleChangeTab} />

      {TABS[currentTab].filterValue === Products.ALUGUEL && (
        <S.Message as="span">
          Aqui são exibidos os contratos do Fiança Locatícia gerados pelo formulário antigo, que foi
          descontinuado em Maio de 2024.
        </S.Message>
      )}

      <FormikProvider value={form}>
        <S.FiltersRow>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '70% 30%',
              gap: '1rem',
              marginTop: '16px',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 32%)',
                gridTemplateRows: '1fr',
                gap: '1rem',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              {!showFilters && (
                <S.ShowFiltersContainer onClick={() => setShowFilters(true)}>
                  <Icon
                    sx={{
                      marginLeft: '10px',
                      marginRight: '5px',
                    }}
                    name="filter"
                  />
                  <Typography
                    content="Filtrar"
                    variant="porto-text-body-2-bold"
                    data-test-id="filter"
                  />
                </S.ShowFiltersContainer>
              )}
              {showFilters && (
                <S.HideFiltersContainer
                  onClick={() => {
                    setShowFilters(false)
                    restartFilters()
                  }}
                  data-test-id="hide-filter"
                >
                  <Icon
                    sx={{
                      marginLeft: '10px',
                      marginRight: '5px',
                    }}
                    name="filter"
                  />
                  <Typography content="Desligar filtros" variant="porto-text-body-2-bold" />
                </S.HideFiltersContainer>
              )}

              {showFilters && (
                <>
                  <FloatingCombo
                    label="Criado"
                    optionsLabel="Data de criação"
                    options={createdAtFilterOptions}
                    error={createdAtFilterCustomHadErrors()}
                    emptySelectionLabel="Últimos 6 meses"
                    singleSelection={true}
                    getLabelSelectedValue={processCreatedAtFilterLabel}
                    filterCallback={applyCreatedAtFilter}
                    onChange={handleCreatedAtFilterChange}
                  >
                    {showCustomCreatedAtFilter && (
                      <S.CustomDateWrapper>
                        <Forms.Datepicker
                          name="dateStart"
                          label="Data inicial"
                          maxDate={form.values.dateEnd}
                        />
                        <Forms.Datepicker
                          name="dateEnd"
                          label="Data final"
                          minDate={form.values.dateStart}
                          maxDate={maxDate}
                        />
                      </S.CustomDateWrapper>
                    )}
                  </FloatingCombo>

                  <FloatingCombo
                    label="Status"
                    options={productStatusFilterOptions}
                    error={false}
                    emptySelectionLabel="Todos"
                    filterCallback={applyStatusFilter}
                  ></FloatingCombo>
                </>
              )}
            </Box>

            <S.SearchFieldWrapper>
              <Forms.InputGroup
                name="search"
                placeholder="CPF/CNPJ, nome, proposta..."
                size="block"
                action={{
                  icon: 'search',
                  handler: () => form.submitForm(),
                }}
                data-test-id="search"
              />
            </S.SearchFieldWrapper>
          </Box>
        </S.FiltersRow>
      </FormikProvider>

      <S.TableWrapper>
        <Table
          columns={TABLE_COLUMNS}
          getOptions={getContractOptions}
          getDataKey={getContractKey}
          renderFooter={() => <Pagination />}
        />
      </S.TableWrapper>
    </PaginationProvider>
  )
}
