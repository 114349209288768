import { api } from '@/services'
import { useQuery, UseQueryOptions } from 'react-query'

export function useRegistration(email: string, options?: UseQueryOptions) {
  const query = useQuery(
    ['api.users.newAccount.registerPartner', email],
    () => api.users.newAccount.registerPartner(email),
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      ...options,
    },
  )

  return query
}
