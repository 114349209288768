import { useFormikContext } from 'formik'
import { ReactNode } from 'react'
import XLSX from 'xlsx'

import { IQuote } from '@/interfaces'

import { Dropzone, Forms } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import downloadBatch from '@/screens/BatchBudget/Options/download-batch-sheet'

import { useCurrentOrganization } from '@/modules'
import { brokerDataTable, dataTable } from '@/screens/BatchBudget/Options/dataTable'
import { setBudgets, setFilename } from '@/store/ducks/batches'
import { isCNPJ, isCPF } from 'brazilian-values'
import { useDispatch } from 'react-redux'
import { Batch } from '../../'
import UploadModal from '../../../components/UploadModal'
import { FileInfo } from '../../styles'

const CONTRACT_PROLABORE_COMISSAO_LIMITE = 70

const File = () => {
  const dispatch = useDispatch()
  const form = useFormikContext<Batch>()
  const profile = useCurrentOrganization()

  const isBroker = profile.type === 'BROKER'

  const handleAlertModal = ({ description, title }: { description: ReactNode; title: string }) =>
    UploadModal({
      color: 'alert',
      description,
      icon: SvgIcons['uploadAlert'],
      title,
    })

  const handleDocument = (document: string): string => {
    if (!document) return ''
    document = document
      .toString()
      .trim()
      .replace(/-|\.|\//gim, '')

    if (document.length === 9 || document.length === 12) return `00${document}`
    if (document.length === 10 || document.length === 13) return `0${document}`

    return document
  }

  async function handleUpdate(file: File) {
    const workbook = XLSX.read(await file?.arrayBuffer(), {
      type: 'buffer',
    })

    if (
      Object.keys(workbook.Sheets).length > 2 ||
      Object.keys(workbook.Sheets)[0] !== 'ORÇAMENTO' ||
      Object.keys(workbook.Sheets)[1] !== 'PROFISSÕES'
    )
      return handleAlertModal({
        description: (
          <>
            Não é permitido criar novas abas na planilha.
            <br /> Certifique-se de que exista somente as abas "
            <span style={{ fontWeight: 'bold' }}>ORÇAMENTO</span>" e "
            <span style={{ fontWeight: 'bold' }}>PROFISSÕES</span>"
          </>
        ),
        title: 'Erro no arquivo.',
      })

    const sheet = workbook.Sheets[workbook.SheetNames[0]]

    const columnKeys = dataTable.map(data => data.key)

    if (isBroker) columnKeys.push(...brokerDataTable.map(data => data.key))

    const rows = await Promise.all(
      XLSX.utils
        .sheet_to_json(sheet, {
          defval: '',
          header: columnKeys,
          range: 1,
        })
        .filter(
          ({ customer_document, customer_name }: IQuote) => customer_document && customer_name,
        )
        .map((el: IQuote) => {
          Object.keys(el).forEach(key => {
            if (el[key] && el[key].length) el[key] = el[key].toString().trim()
          })

          const customer_civil_status =
            Number(el.customer_civil_status.split('-')[0]?.trim()) || undefined
          const customer_income = Number(el.customer_income.split('-')[0]?.trim()) || undefined
          const customer_occupation =
            Number(el.customer_occupation.split('-')[0]?.trim()) || undefined
          const customer_pep = Number(el.customer_pep.split('-')[0]?.trim()) || undefined
          const relationship_type =
            Number(el.pep_relationship_type.split('-')[0]?.trim()) || undefined
          const nationality = Number(el.pep_nationality.split('-')[0]?.trim()) || undefined
          const brazilian_resident =
            Number(el.pep_brazilian_resident.split('-')[0]?.trim()) || undefined
          const type = Number(el.pep_document_type.split('-')[0]?.trim()) || undefined
          const document_date = new Date(Date.UTC(0, 0, Number(el.pep_document_date) - 1, 0, 0, 0))

          const customer_document = handleDocument(el.customer_document)
          const isPF = isCPF(customer_document)
          const isPJ = isCNPJ(customer_document)
          const company_phone = isPJ
            ? `${el.customer_phone_code}${el.customer_phone_number}`
            : undefined
          const customer_phone_number = isPF ? el.customer_phone_number : undefined

          return {
            customer_name: el.customer_name,
            customer_social_name: !!el.customer_social_name && el.customer_social_name !== '' ? el.customer_social_name : undefined,
            customer_email: isPF ? el.customer_email : undefined,
            company_email: isPJ ? el.customer_email : undefined,
            customer_phone_code: el.customer_phone_code,
            customer_phone_number,
            company_phone,
            estate_owner: el.estate_owner,
            address_zipcode: el.address_zipcode,
            address_street: el.address_street,
            address_number: el.address_number,
            address_complement: el.address_complement,
            address_neighborhood: el.address_neighborhood,
            estate_type: el.estate_type,
            fire: el.fire,
            // ? Se valor informado for uma string vazia, envia zero
            eletrical_damages: el.eletrical_damages || 0,
            rental_loss: el.rental_loss || 0,
            civil_responsability: el.civil_responsability || 0,
            vehicle_impacts: el.vehicle_impacts || 0,
            gale: el.gale || 0,
            riot: el.riot || 0,
            contract_services_clauses: el.contract_services_clauses,
            beneficiary_enabled: el.beneficiary_enabled,
            beneficiary_social_name: el.beneficiary_social_name,
            beneficiary_name: el.beneficiary_name,
            beneficiary_zipcode: el.beneficiary_zipcode,
            beneficiary_street: el.beneficiary_street,
            beneficiary_number: el.beneficiary_number,
            beneficiary_complement: el.beneficiary_complement,
            beneficiary_neighborhood: el.beneficiary_neighborhood,
            contract_discount: el.contract_discount,
            contract_operation_code: el.contract_operation_code,
            contract_prolabore: el.contract_prolabore,
            customer_civil_status,
            customer_income,
            customer_occupation,

            beneficiary_document: handleDocument(el.beneficiary_document),
            customer_document,

            customer_pep,
            pep_details: [1, 3].includes(customer_pep)
              ? {
                  name: el.pep_name || undefined,
                  cpf: String(el.pep_cpf) || undefined,
                  relationship_type,
                  nationality,
                  brazilian_resident,
                  document:
                    customer_pep === 1
                      ? {
                          type,
                          code: String(el.pep_document) || undefined,
                          dispatcher: el.pep_document_dispatcher || undefined,
                          expedition_date: [1, 40].includes(type) ? document_date : undefined, //document.type: 1 e 40
                          expiration_date: [5, 2, 39].includes(type) ? document_date : undefined, //document.type: 5, 2 e 39
                        }
                      : undefined,
                }
              : undefined,
          }
        }),
    )

    if (!rows.length)
      return handleAlertModal({
        description: (
          <>
            Por favor, preencha a&nbsp;
            <button
              onClick={() => downloadBatch({ isBroker })}
              style={{
                background: 'transparent',
                border: 0,
                color: '#0645AD',
                cursor: 'pointer',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
              title="Planilha Modelo"
            >
              planilha modelo
            </button>
            &nbsp;e faça uma nova tentativa.
          </>
        ),
        title: 'A planilha enviada está vazia.',
      })
    if (rows.length > 500)
      return handleAlertModal({
        description: (
          <>
            O número máximo de linhas permitidas na planilha é de{' '}
            <span style={{ fontWeight: 'bold' }}>500</span>.
          </>
        ),
        title: 'A planilha enviada excedeu o limite máximo de linhas permitidas.',
      })
    if (rows.some(({ contract_operation_code }) => isBroker && !contract_operation_code))
      return handleAlertModal({
        description: (
          <>
            o preenchimento do campo "<span style={{ fontWeight: 'bold' }}>COMISSÃO</span>" é
            obrigatório.
          </>
        ),
        title: 'Preenchimento comissão.',
      })
    if (
      rows.some(
        ({ contract_operation_code, contract_prolabore }) =>
          isBroker &&
          contract_operation_code +
            (contract_prolabore?.length ? parseFloat(contract_prolabore) : 0) >
            CONTRACT_PROLABORE_COMISSAO_LIMITE,
      )
    )
      return handleAlertModal({
        description: (
          <>
            A soma dos valores nos campos de "<span style={{ fontWeight: 'bold' }}>COMISSÃO</span>"
            e "<span style={{ fontWeight: 'bold' }}>PRÓ-LABORE</span>" não pode exceder{' '}
            <span style={{ fontWeight: 'bold' }}>70%</span>.
          </>
        ),
        title: 'Erro de distribuição',
      })
    if (rows.some(({ address_number }) => !address_number))
      return handleAlertModal({
        description: (
          <>
            O preenchimento do campo "<span style={{ fontWeight: 'bold' }}>NÚMERO</span>" é
            obrigatório.
          </>
        ),
        title: 'Erro de distribuição',
      })

    dispatch(setBudgets(rows))
    dispatch(setFilename(file.name))

    form.setFieldValue('budgets', rows)
    form.setFieldValue('filename', file.name)

    return form.handleSubmit()
  }

  function submitFile([file]: File[]): void {
    handleUpdate(file)
  }

  return (
    <Forms.Card step="3" title="Arquivo" active>
      <FileInfo>
        Após preencher as informações dos{' '}
        {isBroker ? 'clientes do parceiro em' : 'em seus clientes'}{' '}
        <a onClick={() => downloadBatch({ isBroker })} href="#">
          planilha modelo
        </a>
        , selecione o arquivo para inserir em Portal e poder dar início ao cálculo de orçamento.
      </FileInfo>

      <Dropzone onUpload={submitFile} />
    </Forms.Card>
  )
}

export default File
