import { ReactNode, useEffect } from 'react'

import { endTour, setStep, tourStore, useAuth, useCurrentOrganization } from '@/modules'

import Joyride, { CallBackProps, STATUS, Status, Step } from 'react-joyride'
import Chat from '@/components/Chat'
import { Main, Content } from './styles'
import { AlertPersistente, Loading } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { useIsMobile, useMaintenanceInterval } from '@/tools'
import {
  Header,
  Menu,
  AcceptanceTerms,
  ContractUpdateModal,
} from './components'
import * as HeaderStyles from './components/Header/styles'
import * as MenuStyles from './components/Menu/styles'
import { useUnit } from 'effector-react'
import { isBefore, parse } from 'date-fns'
import { useIsCapitalizacaoEnabled, useIsEssencialEnabled } from '@/tools/partnership'

type Props = {
  children: ReactNode
}

const steps: Step[] = [
  {
    placement: 'center',
    target: 'body',
    title: 'O Portal das Imobiliárias está de cara nova!',
    content:
      'Faça um passeio guiado pela nossa nova estrutura e saiba onde acessar as principais funcionalidades.',
    disableBeacon: true,
    locale: { next: 'Iniciar' },
    showProgress: false,
  },
  {
    placement: 'right-start',
    target: `.${MenuStyles.Content.styledComponentId}`,
    title: 'Páginas e produtos',
    content:
      'No menu, você acessa todas as páginas, como Gestão de Carteira e Novidades, além de cada um dos produtos.',
    disableBeacon: true,
    spotlightPadding: 0,
    locale: { next: 'Próximo (1/4)' },
  },
  {
    target: `#tour-id`,
    title: 'Funcionalidades dos produtos',
    content:
      'Agora, você pode manter aberta ou fechada a lista de funcionalidades de um produto. Assim, acessa com agilidade as funcionalidades que usa mais.',
    placement: 'auto',
    disableBeacon: true,
    spotlightPadding: 0,
    locale: { next: 'Próximo (2/4)' },
  },
  {
    target: `.${HeaderStyles.OrganizationMenu.styledComponentId}`,
    title: 'Opções da organização',
    content:
      'No botão com o nome da sua empresa, você acessa as configurações dela ou troca de organização.',
    placement: 'left',
    disableBeacon: true,
    spotlightPadding: 28,
    locale: { next: 'Próximo (3/4)' },
  },
  {
    target: `.${HeaderStyles.AccountMenu.styledComponentId}`,
    title: 'Opções da conta',
    content:
      'No botão do seu perfil, você acessa as configurações da conta ou desloga do Portal das Imobiliárias.',
    placement: 'left',
    disableBeacon: true,
    spotlightPadding: 31,
    showSkipButton: false,
    locale: { last: 'Finalizar (4/4)' },
  },
]

export default function Dashboard(props: Props) {
  const isMobile = useIsMobile()
  const [tour, endTourFn, setStepFn] = useUnit([tourStore, endTour, setStep])

  const { isAuthenticated } = useAuth()

  const maintenanceInterval = useMaintenanceInterval()

  const profile = useCurrentOrganization()
  const { result: isEssencialEnabled, isLoading: isEssencialEnabledLoading } = useIsEssencialEnabled(profile)
  const { result: isCapitalizacaoEnabled, isLoading: isCapitalizacaoEnabledLoading } = useIsCapitalizacaoEnabled(profile)

  useEffect(() => {
    if (isMobile && !tour.completed) {
      endTourFn()
    }
  }, [isMobile, tour.completed])

  useEffect(() => {
    const openContractUpdateModal = async () => {
      await ContractUpdateModal()
    }

    const LIMIT_DATE = '30/04/2024'
    const today = new Date()
    const isBeforeLimitDate = isBefore(
      today,
      parse(LIMIT_DATE, 'dd/MM/yyyy', today)
    )

    if (isBeforeLimitDate && tour.completed) {
      openContractUpdateModal()
    }
  }, [tour.completed])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, status } = data

    setStepFn(index)

    if (([STATUS.FINISHED, STATUS.SKIPPED] as Status[]).includes(status)) {
      endTourFn()
    }
  }

  return (
    <>
      {/* {isEssencialEnabledLoading && <Loading />} */}
      {/* {isCapitalizacaoEnabledLoading && <Loading />} */}
      <Joyride
        run={!tour.completed && !isMobile}
        locale={{
          back: 'Voltar',
          close: 'Fechar',
          last: 'Finalizar',
          next: 'Próximo',
          open: 'Open the dialog',
          skip: 'Pular guia',
        }}
        steps={steps}
        showSkipButton
        hideCloseButton
        disableScrolling
        continuous
        callback={handleJoyrideCallback}
        styles={{
          buttonNext: {
            background: '#0046C0',
            padding: '6px 16px',
            border: 'none',
            color: 'var(--Neutras-White, #FFF)',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '20px',
            outline: 'none',
          },
          buttonSkip: {
            color: 'var(--porto-seguro-holding-porto-seguro-100, #0046C0)',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '20px',
          },
          buttonBack: {
            borderRadius: '4px',
            border:
              '1px solid var(--porto-seguro-holding-porto-seguro-100, #0046C0)',
            color: 'var(--porto-seguro-holding-porto-seguro-100, #0046C0)',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '20px',
            marginRight: '16px',
            padding: '5px 15px',
          },
          options: { width: 432 },
          tooltip: {
            padding: '20px 16px',
          },
          tooltipContent: {
            color: 'var(--neutras-black-75, #404040)',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            padding: '16px 0 25px',
          },
          tooltipTitle: {
            color: '#000',
            fontFamily: 'Porto Roobert',
            fontSize: ' 20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '28px',
            letterSpacing: '0.4px',
            textAlign: 'left',
          },
          overlay: {
            mixBlendMode: 'darken',
          },
          spotlight: {
            backgroundColor: 'white',
          },
        }}
      />
      <Header />
      <Menu essencialEnabled={isEssencialEnabled} capitalizacaoEnabled={isCapitalizacaoEnabled} />
      <Main>
        {maintenanceInterval && (
          <AlertPersistente icon="information">
            <Typography as="span" variant="porto-text-body-2-regular">
              Atenção, o Portal das Imobiliárias está passando por manutenção
              entre {maintenanceInterval}, e pode apresentar instabilidade. Em
              breve os serviços serão normalizados.
            </Typography>
          </AlertPersistente>
        )}
        <AcceptanceTerms />
        <Content>{props.children}</Content>

        {isAuthenticated && <Chat />}
      </Main>
    </>
  )
}
