import React, { Fragment, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Conditional, Content } from '@/components'
import { useContract } from '@/modules'
import Error from '@/screens/Errors'

import * as Products from '../Products'
import * as S from './styles'

const Details = () => {
  const params = useParams<{ id: string }>()
  const contract = useContract()
  const { state } = useLocation<{ renovation: boolean }>()

  useEffect(() => {
    contract.fetch({
      id: params.id,
    })

    return () => {
      contract.resetStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const renderHeader = useMemo(() => {
    return ['PORTO_IMOBILIARIA', 'PORTO_ALUGUEL'].includes(contract.product)
  }, [contract.product])

  return (
    <S.Container>
      <Conditional when={renderHeader}>
        <Content.Heading
          title={
            {
              PORTO_ALUGUEL: 'Fiança Locatícia',
              PORTO_IMOBILIARIA: state?.renovation ? 'Confirmação da renovação' : 'Seguro Incêndio',
              PORTO_CAPITALIZACAO: 'Contrato',
              PORTO_ESSENCIAL: 'Contrato',
            }[contract.product]
          }
          subtitle={
            {
              PORTO_ALUGUEL:
                'Substitui com vantagens o papel do fiador, com vigência que acompanha o contrato de locação.',
              PORTO_IMOBILIARIA:
                'Oferece cobertura obrigatória por lei, contra incêndio, para imóveis alugados.',
              PORTO_CAPITALIZACAO: undefined,
              PORTO_ESSENCIAL: undefined,
            }[contract.product]
          }
          actions={(!!contract?.budgets.length && {}[contract.product]) || []}
        />
      </Conditional>
      <Conditional when={contract.queryStatus === 'error'}>
        <S.Section>
          <Error title="Atenção" subtitle="Contrato não foi Localizado." />
        </S.Section>
      </Conditional>

      <Conditional when={contract.id !== params.id && contract.queryStatus !== 'error'}>
        <Content.Loader message="Processando os dados. Por favor, aguarde." />
      </Conditional>

      <Conditional when={contract.queryStatus === 'loading'}>
        <Content.Loader message="Aguarde, estamos buscando o contrato." />
      </Conditional>

      <Conditional when={['syncing', 'ordering'].includes(contract._status)}>
        <Content.Loader message="Aguarde, estamos atualizando o contrato." />
      </Conditional>

      <Conditional
        when={
          ['success'].includes(contract.queryStatus) &&
          !['syncing', 'ordering'].includes(contract._status)
        }
      >
        <S.Content>
          {React.createElement(
            {
              PORTO_ESSENCIAL: Products.Essencial.Details,
              PORTO_ALUGUEL: Products.Aluguel.Details,
              PORTO_IMOBILIARIA: Products.Imobiliaria.Details,
              PORTO_CAPITALIZACAO: Products.Capitalizacao.Details,
            }[contract.product] || Fragment,
          )}
        </S.Content>
      </Conditional>
    </S.Container>
  )
}

export default Details
